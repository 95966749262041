import { setFocusViewElement } from '../views';
import { updateAttendee, incAttendeeCount } from '.';
import { changeColor } from '../whiteboard';
import { connected, session } from '../session';
import { action } from './store';
import { AttendeeState } from '../../utils/Types';
import Builder from '../../utils/Builder';

export const applyAgendaUpdate = action('apply-agenda-update', (data, mutate) => {
  const eventData = JSON.parse(data.data);
  mutate(() => {
    setFocusViewElement({ view: eventData.view, data: eventData.data });
  });
});

/**
 * admin changes the users color via colorPicker locally and
 * sends out the signal to change on their machine
 * @param attendee
 */
export const changeUserColor = action('change-user-color', (attendee, mutate) => {
  let { color, connectionId } = attendee.value;

  mutate(() => {
    updateAttendee({ color, connectionId });

    if (connected) {
      session.value.signal(
        {
          type: 'otAttendee_change_color',
          data: JSON.stringify({ color, connectionId }),
        },
        (e) => console.log,
      );
    }
  });
});

/**
 * receive a signal in whiteboard and change the attendees color on their machine
 * @param data
 */
export const changeColorByConnectionId = action('change-color-by-connection-id', (data, mutate) => {
  mutate(() => {
    let { color, connectionId } = data;

    updateAttendee({ color, connectionId });

    //only change the color of the selected user: match their connectionID
    if (session.value.connection.connectionId === connectionId) {
      changeColor(color);
    }
  });
});

export function broadcastAttendeeNameUpdate(payload) {
  if (connected.value) {
    session.value.signal(
      {
        type: 'attendee_name_update',
        data: JSON.stringify(payload),
      },
      (e) => console.log,
    );
  }
}

export const setAttendeeTalkingStatus = action('setAttendeeTalkingStatus', (update, mutate) => {
  let { talking, connectionId } = update;
  mutate((state: AttendeeState) => {
    let someoneElseTalking = Object.values(state.attendees).filter((attendee) => {
      return attendee.talking;
    });
    if (talking) {
      if (!someoneElseTalking.length) {
        updateAttendee({ talking, focused: true, connectionId });
      } else {
        updateAttendee({ talking, focused: false, connectionId });
      }
    } else {
      updateAttendee({ talking, focused: false, connectionId });
      if (someoneElseTalking.length && someoneElseTalking[0].connectionId !== connectionId) {
        updateAttendee({ focused: true, connectionId: someoneElseTalking[0].connectionId });
      }
    }
  });
});

export const markAttendeeAsPresent = action('markAttendeeAsPresent', async (attendeeInfo, mutate) => {
  let { userId } = attendeeInfo;
  let response;

  mutate( () => {
    incAttendeeCount();
  });

  try {
    response = await Builder.post(`/session/user/attendance`, {userId});
  } catch(e) {
    throw e;
  }
});
