
import { defineComponent, ref, watchEffect, onMounted, inject } from 'vue';

import Menu from 'primevue/menu';

import { audioOutputs, setAudioOutput } from '../../../store/session';

export default defineComponent({
  name: 'SpeakerSwitch',
  components: {
    Menu,
  },
  setup() {
    const menu = ref(null);
    const audioOutputsMenuData = ref([]);
    // const noBrowserSupport = inject('noBrowserSupport');

    const toggleSpeakerList = toggle => {
      menu.value.toggle(toggle);
    };

    const selectSpeaker = audioOutput => {
      //console.log(audioOutput);
      setAudioOutput(audioOutput);
    };

    watchEffect(() => {
      audioOutputsMenuData.value = [];
      audioOutputs.value?.forEach(audioOutput => {
        audioOutputsMenuData.value.push({
          label: audioOutput.label,
          icon: audioOutput.selected ? 'pi pi-check' : null,
          command: () => selectSpeaker(audioOutput),
        });
      });
    });

    return {
      menu,
      toggleSpeakerList,
      audioOutputsMenuData,
      // noBrowserSupport
    };
  },
});
