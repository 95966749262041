import OT, { Session, Stream } from '@opentok/client';

import {
  removeStreamById,
  setSessionConnected,
  setSessionObject,
  addStreamById,
  setScreenShareStream,
  setApprovedByClinician,
  setSessionDisconnected,
  setCallActive,
} from '../store/session';

import { handleNewChatMessage } from '../store/chat';

import { clinician, setFirstName, setLastName } from '../store/user';

import {
  removeAttendee,
  addPendingAttendee,
  removePendingAttendee,
  applyAgendaUpdate,
  addConnectedAttendee,
  removeConnectedAttendee,
  updateAttendee,
  changeColorByConnectionId,
} from '../store/attendees';

import CallActions from '../utils/CallActions';

import { setScreenShareActive, setVideoStatus, setWhiteboardStatus } from '../store/views';

import { AttendeeInfo, MessageType } from '../utils/Types';
import { markRaw } from 'vue';

export default class SessionAdapter extends Session {
  private session: Session;
  private token: string;
  private apiKey: string;

  constructor(apiKey, sessionId, token) {
    super();
    this.apiKey = apiKey;
    this.token = token;
    this.sessionId = sessionId;
  }

  private initListeners() {
    this.session.on({
      connectionCreated: (event) => {
        console.log('connection created');
        CallActions.fetchCallStatus();
      },
      connectionDestroyed: (event) => {
        const destroyedConnectionId = event.connection.connectionId;
        removeAttendee(destroyedConnectionId);
        removeConnectedAttendee(destroyedConnectionId);
        console.log('connection destroyed');
      },
      sessionDisconnected: (event) => {
        setSessionDisconnected();
        setCallActive(false);
        if (event.reason == 'networkDisconnected') {
          alert('Your network connection terminated');
        }
      },
      streamCreated: (event) => {
        if (event.stream.videoType === 'screen') {
          setScreenShareStream(event.stream);
          return;
        }
        this.getModeratorApproval(event.stream);
      },
      streamDestroyed: (event) => {
        removeStreamById(event.stream.streamId);
      },
      streamPropertyChanged: (event) => {},
    });

    this.session.on('signal:agenda', applyAgendaUpdate);

    this.session.on('signal:video', (event: any) => {
      setVideoStatus({ playing: event.data, sendSignal: false });
    });

    this.session.on('signal:whiteboard', (event: any) => {
      if (clinician.value) return;
      setWhiteboardStatus({ bool: !!JSON.parse(event.data), moderator: false });
    });

    this.session.on('signal:screen_share', (event: any) => {
      if (clinician.value) return;
      setScreenShareActive(JSON.parse(event.data));
    });

    this.session.on('signal:public_message', (event: any) => {
      const chatMessageObj = {
        from: event.from.connectionId,
        messageType: MessageType.Public,
        message: event.data,
        to: null,
      };
      handleNewChatMessage(chatMessageObj);
    });

    this.session.on('signal:private_message', (event: any) => {
      const chatMessageObj = {
        from: event.from.connectionId,
        messageType: MessageType.Private,
        message: event.data,
        to: this.session.connection.connectionId,
      };
      handleNewChatMessage(chatMessageObj);
    });

    this.session.on('signal:attendee_connected', (event: any) => {
      addConnectedAttendee(JSON.parse(event.data));
    });

    this.session.on('signal:attendee_approved_init', (event: any) => {
      if (clinician.value) return;
      let { streams, attendees } = JSON.parse(event.data);
      setApprovedByClinician(true);
      Object.keys(streams).forEach((key) => {
        const attendeeConnectionId = streams[key].connection.connectionId;
        this.parseStream(streams[key], attendees[attendeeConnectionId]);
      });
    });

    this.session.on('signal:attendee_approved', (event: any) => {
      if (clinician.value) return;
      let { streams, attendees } = JSON.parse(event.data);
      this.parseStream(streams, attendees);
    });

    this.session.on('signal:attendee_name_update', (event: any) => {
      let payload = JSON.parse(event.data);
      if (payload.connectionId === this.session.connection.connectionId) {
        let [first, last] = payload.name.split(' ');
        if (first) setFirstName(first);
        if (last) setLastName(last);
      }
      updateAttendee(JSON.parse(event.data));
    });

    this.session.on('signal:otAttendee_change_color', (event: any) => {
      changeColorByConnectionId(JSON.parse(event.data));
    });
  }

  private parseStream(stream: Stream, attendee: AttendeeInfo) {
    addStreamById({ streamId: stream.streamId, attendee });
    removePendingAttendee(stream.streamId);
  }

  public initialize() {
    this.session = OT.initSession(this.apiKey, this.sessionId);

    this.session.connect(this.token, (error) => {
      if (error) {
        console.log(error);
        return;
      }
      this.initListeners();
      this.session = setSessionObject(markRaw(this.session));
      setSessionConnected();
    });
  }

  public disconnect() {
    this.session.disconnect();
  }

  private getModeratorApproval(stream) {
    addPendingAttendee(stream);
  }
}
