import { mutation } from './store';

export const setUserId = mutation('setUserId', (state, id: string) => {
  state.id = id;
});

export const setUserType = mutation('setUserType', (state, role: string) => {
  if (role === 'host') {
    state.clinician = true;
  } else {
    state.student = true;
  }
});

export const setIdentifyingInfo = mutation('setIdentifyingInfo', (state, token: string) => {
  state.bearerToken = token;
  const [,payload,] = token.split('.');
  let {given_name, family_name, email} = JSON.parse(atob(payload));
  setFirstName(given_name);
  setLastName(family_name);
  setEmail(email);
});

export const setFirstName = mutation('setFirstName', (state, name: string) => {
  state.firstName = name;
});

export const setLastName = mutation('setLastName', (state, name: string) => {
  state.lastName = name;
});

export const setEmail = mutation('setEmail', (state, email: string) => {
  state.email = email;
  return state.email;
});