import { Publisher, Session, Stream } from '@opentok/client';

/**
 * Info required to initialize a session
 * @typedef {Object<string, any>} CallCredentials
 * @property {string} meeting_id The id returned from the api
 * @property {number} [client_id] The customer id
 * @property {number} user_id Current user's ID, not always available, Optional
 * @property {number} [foreign_user_id] ID of a third party, ex. parent, other clinician, etc. Optional
 */
export interface CallCredentials {
  meeting_id: string;
  client_id?: number;
  user_id: number;
  foreign_user_id?: number;
}

/**
 * Info required to disconnect from a session (if user_id is available)
 * @typedef {DisconnectCallOptions} DisconnectCallOptions
 * @property {string | number} [user_id] optional id, if available, of user disconnecting from call
 */
export interface DisconnectCallOptions {
  user_id?: string | number;
}

/**
 * Session state object
 * @typedef {Object<string, any>} SessionState
 * @property {Session} session The session object created by opentok.
 * @property {boolean} connected Whether user is connected to the session.
 * @property {number} connectionCount Number of users connected to the session.
 * @property {boolean} approvedByClinician Whether the user has been accepted into the call.
 * @property {boolean} callActive Whether the user has published their audio/video to the session.
 * @property {Object | StreamMap} streams Published audio/video from other users.
 * @property {Publisher} publisher The publisher object created by opentok.
 * @property {Publisher} screenSharePublisher Publisher object devoted to screen share (Host only).
 * @property {AudioInput[]} audioInputs Detected microphones available to use as an input.
 * @property {VideoInput[]} videoInputs Detected cameras available to use as an input.
 * @property {AudioOutput[]} audioOutputs Detected audio output devices available.
 * @property {boolean} videoActive Is the current user's camera turned on.
 * @property {boolean} audioActive Is the current user's microphone turned on.
 * @property {boolean} joinDisabled Whether the room is ready to be joined by participant.
 * @property {boolean} hostConnected Whether the host has connected to the session.
 */
export interface SessionState {
  session: Session;
  publisherStreamReady: boolean;
  connected: boolean;
  connectionCount: number;
  approvedByClinician: boolean;
  callActive: boolean;
  streams: {} | StreamMap;
  publisher: Publisher;
  publisherElement: HTMLVideoElement | HTMLElement;
  screenSharePublisher: Publisher;
  screenShareStream: Stream;
  audioInputs: AudioInput[];
  audioOutputs: AudioOutput[];
  videoInputs?: VideoInput[];
  videoActive: boolean;
  audioActive: boolean;
  joinDisabled: boolean;
  hostConnected: boolean;
}

/**
 * Camera properties detected from opentok
 * @typedef {Object<string, any>} VideoInput
 * @property {string} deviceId The id or name specified for a camera device
 * @property {string} kind The kind of input device
 * @property {string} label Label or brand of video input device
 * @property {boolean=} selected Whether it is the currently selected device
 * @memberof SessionState
 * @alias videoInputs
 */
export interface VideoInput {
  deviceId: string;
  kind: 'videoInput';
  label: string;
  selected?: boolean;
}

/**
 * Microphone properties detected from opentok
 * @typedef {Object<string, any>} AudioInput
 * @property {string} deviceId The id of the specified audio input device
 * @property {string} kind The kind of input device
 * @property {string} label Label or brand of audio input device
 * @property {boolean} selected Whether it is the currently selected device
 * @memberof SessionState
 * @alias audioInputs
 */
export interface AudioInput {
  deviceId: string;
  kind: 'audioInput';
  label: string;
  selected?: boolean;
}

/**
 * Speaker properties detected from browser
 * @typedef {Object<string, any>} AudioOutput
 * @property {string} deviceId The id of the specified audio output device
 * @property {string} kind The kind of output device
 * @property {string} label Label or brand of audio output device
 * @property {boolean} selected Whether it is the currently selected device
 * @extends {MediaDeviceInfo}
 * @memberof SessionState
 * @alias audioOutputs
 */
export interface AudioOutput extends MediaDeviceInfo {
  selected?: boolean;
}

/**
 * Moderator state object
 * @typedef {Object<string, any>} ModeratorState
 * @property {AgendaItem[]} agenda Items to be displayed in the agenda panel
 * @property {MediaItem[]} media Items to be displayed in the media panel
 * @property {NoteItem[]} notes Note items added to the note panel by the moderator
 */
export interface ModeratorState {
  agenda: AgendaItem[];
  media: MediaItem[];
  notes: NoteItem[];
}

/**
 * Current user state object
 * @typedef {Object<string, any>} UserState
 * @property {string=} id Identifier of current user, not always available, Optional
 * @property {boolean} clinician Is the current user a clinician
 * @property {boolean} student Is the current user a student
 * @property {string} bearer_token Token used as Authorization header in requests
 * @property {string} firstName Given name pulled from auth token
 * @property {string} lastName Family name pulled from auth token
 * @property {string} email Email address pulled from auth token
 */
export interface UserState {
  id?: string;
  clinician: boolean;
  student: boolean;
  bearerToken: string;
  firstName: string;
  lastName: string;
  email: string;
}

/**
 * View state object
 * @typedef {Object<string, any>} ViewState
 * @property {HTMLElement} focusViewElement Current element assigned to the focus view
 * @property {FocusViewInfo} focusViewInfo Info related to the current focus view
 * @property {boolean} whiteboardEnabled Is the whiteboard active
 * @property {boolean} screenShareActive Is screen share currently active
 * @property {boolean} hostMenuOpen Is the host menu showing, always false for non host
 * @property {HostMenuName} hostMenuSelection Name of currently selected host menu tab, null for non host
 * @property {boolean} videoStatus Is a video currently playing for all users
 * @property {boolean} leaveCallPopupOpen Is the leave call dialog box open
 * @property {CallLayouts} callLayout Layout selection for the call view
 */
export interface ViewState {
  focusViewElement: HTMLElement;
  focusViewInfo: FocusViewInfo;
  whiteboardEnabled: boolean;
  hostMenuOpen: boolean;
  hostMenuSelection: HostMenuName;
  videoStatus: boolean;
  screenShareActive: boolean;
  leaveCallPopupOpen: boolean;
  callLayout: CallLayouts;
}

export enum CallLayouts {
  SPOTLIGHT = 'spotlight',
  TILED = 'tiled',
}

/**
 * Info associated with different ValidViews
 * @typedef {Object<ValidViews, object>} FocusViewInfo
 * @property {ValidViews} view Name of view being described
 * @property {object} data Any data associated with the view
 * @memberof ViewState
 * @alias focusViewInfo
 */
export interface FocusViewInfo {
  view: ValidViews;
  data?: {
    videoId?: string;
  };
}

//  * @type {'agenda'|'notes'|'admin'|'whiteboard'}
/**
 * Host menu selection types
 * @readonly
 * @enum {'agenda'|'notes'|'admin'|'whiteboard'}
 * @memberof! ViewState
 * @alias hostMenuSelection
 */
export enum HostMenuName {
  AGENDA = 'agenda',
  NOTES = 'notes',
  GOALS = 'goals',
  CHAT = 'chat',
  SETTINGS = 'settings',
}

/**
 * Object map of streams connected to current session
 * @typedef {Object.<string, Stream>} StreamMap
 * @memberof SessionState
 * @alias streams
 */
export type StreamMap = { key: string; value: Stream };

/**
 * Accepted views to be presented through agenda items
 * @readonly
 * @enum {'default'|'whiteboard'|'video'|'screen'|'test'}
 * @memberof FocusViewInfo
 * @alias view
 */
export enum ValidViews {
  DEFAULT = 'default',
  WHITEBOARD = 'whiteboard',
  VIDEO = 'video',
  SCREEN = 'screen',
  TEST = 'test',
}

/**
 * Data property attached to agenda items to provide any necessary
 * information about what should be shown in the view
 * @typedef {Object<string,any>} AgendaData
 * @property {string} [assetUrl] url of agenda assets if applicable
 * @property {string} [videoId] id of youtube/backpack video, if applicable
 * @property {function} [callback] callback function to run along when item is chosen
 * @memberof AgendaItem
 * @alias data
 */
export interface AgendaData {
  assetUrl?: string;
  videoId?: string;
  callback?: (e?) => void;
}

/**
 * Item to be displayed in the agenda panel
 * @typedef {Object<string, any>} AgendaItem
 * @property {number} id Unique number identifier
 * @property {string} title Title to be displayed at the top of the agenda item
 * @property {string} subtitle The content of the agenda item to be displayed below the title
 * @property {boolean} done Whether the agenda item is marked as completed
 * @property {boolean} active Whether the agenda item is currently focused
 * @property {ValidViews} view What is to be shown in the main focus view, if active
 * @property {AgendaData | null} data Any data required to display in the focus view
 * @memberof ModeratorState
 * @alias agenda
 */
export interface AgendaItem {
  id: number;
  title: string;
  subtitle: string;
  done: boolean;
  active: boolean;
  view: ValidViews;
  data: AgendaData | null;
}

/**
 * Smaller object for only necessary properties for state
 * @typedef {{id: number, done: boolean}} AgendaPreview
 */
export type AgendaPreview = Pick<AgendaItem, 'id' | 'done'>;

/**
 * Item to be displayed in the media panel
 * @typedef {Object<string, any>} MediaItem
 * @property {number} id Unique number identifier
 * @property {string} title Title to be displayed at the top of the media item
 * @property {string} subtitle The content of the media item to be displayed below the title
 * @property {NodeRequire} src A url for the thumbnail to be displayed for a media item
 * @memberof ModeratorState
 * @alias media
 */
export interface MediaItem {
  id: number;
  title: string;
  subtitle: string;
  src: NodeRequire;
}

/**
 * Item to be displayed in the note panel
 * @typedef {Object<string, any>} NoteItem
 * @property {number} id Unique number identifier
 * @property {string} title Title to be displayed at the top of the note item
 * @property {string} content Content displayed below the title
 * @memberof ModeratorState
 * @alias notes
 */
export interface NoteItem {
  id: number;
  title: string;
  content: string;
}

/**
 * Smaller object with only necessary properties for state
 * @typedef {{id: number, content: string}} NotePreview
 */
export type NotePreview = Pick<NoteItem, 'id' | 'content'>;

/**
 * Attendee info object
 * @typedef {Object<string, any>} AttendeeInfo
 * @property {number} [index] Order in which attendee is shown in the attendee drawer
 * @property {string} [streamId] Id of the corresponding video stream
 * @property {string} name Name of attendee
 * @property {boolean} [audio] Whether their mic is on
 * @property {boolean} [video] Whether their camera is on
 * @property {boolean} [talking] Whether the attendee is currently making sound
 * @property {string} color Hex code for the attendee's border and marker color with the whiteboard
 * @property {boolean} [focused] Whether the attendee is currently focused in the focus view window
 * @memberof AttendeeDictionary
 */
export interface AttendeeInfo {
  index?: number;
  streamId: string;
  connectionId: string;
  name: string;
  audio?: boolean;
  video?: boolean;
  talking?: boolean;
  color?: string;
  focused?: boolean;
  userId?: string;
  videoEl?: HTMLVideoElement | HTMLObjectElement;
}

/**
 * Attendee state object
 * @typedef {Object<string, any>} AttendeeState
 * @property {AttendeeDictionary} attendees Object map of attendees keyed by index
 * @property {number} attendeeCount Number of active attendees
 * @property {any[]} pendingAttendees Attendees waiting for approval to join
 * @property {number} pendingAttendeeCount Number of attendees waiting to be approved
 * @property {any[]} connectedAttendees Attendees in the lobby
 * @property {number} connectedAttendeeCount Number of attendees waiting in lobby
 */
export interface AttendeeState {
  attendees: AttendeeDictionary;
  attendeeCount: number;
  pendingAttendees: any[];
  pendingAttendeeCount: number;
  connectedAttendees: any[];
  connectedAttendeeCount: number;
}

/**
 * Dictionary of attendees by connectionId paired with AttendeeInfo
 * @typedef {Object.<string, AttendeeInfo>} AttendeeDictionary
 * @memberof AttendeeState
 * @alias attendees
 */
export interface AttendeeDictionary {
  [key: string]: AttendeeInfo;
}

export interface ChatState {
  open: boolean;
  chatGroupSelected: string;
  chatGroups: DictionaryWithGroupsAsValues<ChatGroup>;
  hasNewMessages: boolean;
}

/**
 * Chat Groups object
 * @typedef {Object<string, ChatGroup>} DictionaryWithGroupsAsValues
 */
export interface DictionaryWithGroupsAsValues<Group> {
  [key: string]: Group;
}

/**
 * Chat Groups object
 * @typedef {Object<Group, string>} StringKeyDictionary
 */
 export type StringKeyDictionary = {
  [key: string]: string;
}

/**
 * Chat group
 * Holds all ChatMessageGroups for a single peer to peer or peer to 'all'
 * chat session.
 * @typedef {Object<string, any>} ChatGroup
 * @property {string} id Connection id associated with the user, is null if it's all user chat
 * @property {name} name Name of user associated with the chat group
 * @property {string} [color] Name or hexcode of color assigned to avatar border color
 * @property {boolean} active Connection status of this chat group
 * @property {ChatMessageGroup[]} messages Array of ChatMessages for this group, new messages are appended to array
 */
export interface ChatGroup {
  // id: string;
  name: string;
  color?: string;
  active: boolean;
  messages: Array<ChatMessageGroup>;
  newMessageCount?: number;
}

/**
 * Chat message group
 * This is a subset of a chat group, to show subsequent messages from
 * a single user before anyone else has sent a message
 * @typedef {Object<string, any>} ChatMessageGroup
 * @property {string} [name] Name of the user this group is associated with
 * @property {string} [color] Color associated with this group
 * @property {MessageType} [messageType] Message type, public or private
 * @property {string} from Connection id of the sender
 * @property {string} to Connection id of the recipient, can be 'all'
 * @property {ChatMessage[]} messages Messages assigned to this group
 * @memberof ChatGroup
 * @alias messages
 */

export interface ChatMessageGroup {
  name?: string;
  color?: string;
  messageType?: MessageType;
  from: string;
  to: string;
  messages: Array<ChatMessage>;
}

/**
 * Chat message
 * Individual messages, stored within ChatMessageGroups
 * @typedef {Object<string,any>} ChatMessage
 * @property {string} timestamp Time message was sent
 * @property {string} message Message string sent from user
 * @property {boolean} [seen] To be used with notifications, whether the chat has been seen by this user
 * @memberof ChatMessageGroup
 * @alias messages
 */
export interface ChatMessage {
  timestamp: string;
  message: string;
  seen: boolean;
}

/**
 * Message types, private are one peer to one peer, public are one peer to all peers
 * @readonly
 * @enum {'public_message'|'private_message'}
 * @memberof ChatMessageGroup
 * @alias messageType
 */
export enum MessageType {
  Public = 'public_message',
  Private = 'private_message',
}

export interface WhiteboardState {
  canvas: HTMLCanvasElement;
  whiteboardPaper: paper.PaperScope;
  history: HistoryItem[];
  textStack: paper.PointText[];
  pathStack: paper.Path[];
  eraseStack: paper.Path[];
  batchUpdates: (UpdateItem | string)[];
  erasing: boolean;
  drawing: boolean;
  textToggled: boolean;
  color: string;
  colors?: WhiteboardColor[];
  client: WhiteboardClient;
}

export type HistoryItem = { userId: string; value: UpdateItem };

export type UpdateItem = {
  id: string;
  uuid: string;
  event: UpdateEventType;
  fromX?: number;
  fromY?: number;
  toX?: number;
  toY?: number;
  mode?: UpdateEventMode;
  color?: string;
  visibility?: boolean;
  visible?: boolean;
  content?: string;
};

export enum UpdateEventType {
  START = 'start',
  DRAG = 'drag',
  END = 'end',
  REMOVETEXT = 'removetext',
  ADDTEXT = 'addtext',
}

export enum UpdateEventMode {
  ERASER = 'eraser',
  TEXT = 'text',
  PEN = 'pen',
}

export interface WhiteboardColors {
  WhiteboardColor;
}

export interface WhiteboardColor {
  color: string;
}

export interface WhiteboardClient {
  dragging: boolean;
  lastX: number;
  lastY: number;
  uuid: string;
}

export interface NotesState {
  open: boolean;
  noteGroupSelected: string;
  noteGroups: DictionaryWithGroupsAsValues<NoteGroup>;
}

export interface NoteGroup {
  name: string;
  noteTopics: NoteTopics[];
  noteData: StringKeyDictionary;
}

export enum NoteTopics {
  SUBJECTIVE = 'subjective',
  OBJECTIVE = 'objective',
  ACTION = 'action',
  PLAN = 'plan',
}

export interface GoalsState {
  open: boolean;
  goalGroupSelected: string;
  goalGroups: DictionaryWithGroupsAsValues<GoalGroup>;
}

export interface GoalGroup {
  id?: string;
  name: string;
  goalData: Goal[];
}

export interface Goal {
  id?: number;
  description: string;
  type: GoalTypes;
  maxValue: number;
  expectedValue?: number;
  actualValue: number;
}

export enum GoalTypes {
  PERCENTAGE = 'percentage',
  TRIALS = 'trials',
}