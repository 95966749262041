import { CallLayouts, HostMenuName, ViewState } from '../../utils/Types';

export const STATE: ViewState = {
  focusViewInfo: null,
  focusViewElement: document.querySelector('#main_focus_viewer') || null,
  whiteboardEnabled: false,
  screenShareActive: false,
  hostMenuOpen: false,
  hostMenuSelection: HostMenuName.AGENDA,
  videoStatus: false,
  leaveCallPopupOpen: false,
  callLayout: CallLayouts.SPOTLIGHT,
};