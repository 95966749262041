import {
  toggleChatWindow,
  setChatGroupMessagesToSeen,
  addNewChatMessage,
  updateNewMessageCount,
  resetAllActiveGroups,
  setActiveGroupSelected,
  open,
  hasNewMessages,
  setHasNewMessages,
  getTotalUnreadMessageCount,
} from '.';
import { ChatMessage, ChatMessageGroup } from '../../utils/Types';

/**
 * open the main chat drawer,
 * toggle the window. This tells whatever sub chat selected to reset seen msg
 */
export const openChatDrawer = () => {
  toggleChatWindow(true);
};

/**
 * close the chat drawer and check the notifications for any missed messages
 */
export const closeChatDrawer = () => {
  getTotalUnreadMessageCount();
  toggleChatWindow(false);
};

/**
 * when a sub chat is open set it to active and unset all other active groups
 */
export const handleSubChatOpen = () => {
  resetAllActiveGroups();
  setActiveGroupSelected();
  setChatGroupMessagesToSeen();
};

/**
 * when subChat button is selected set all its messages to seen and hide the notification
 */
export const handleSubChatUpdate = () => {
  setChatGroupMessagesToSeen();
  getTotalUnreadMessageCount();
};

/**
 * handle a new chat message, add to messages array and update notifications
 * if the other users chat window isnt open and already doesnt have new messages
 * @param message: ChatMessage
 */
export const handleNewChatMessage = (
  message: Pick<ChatMessageGroup, 'messageType' | 'from' | 'to'> & Pick<ChatMessage, 'message'>,
) => {
  addNewChatMessage(message);
  //update all new unread message count for recipient
  updateNewMessageCount(message.from);
  //if chat window of recipient is closed then show notification there
  if (!open.value && !hasNewMessages.value) {
    setHasNewMessages(true);
  }
};
