import {
  setPublisher,
  setScreenSharePublisher,
  setAvailableAudioInputs,
  setAvailableVideoInputs,
  callActive,
  sessionPublish,
  publisher,
  sessionUnpublish,
  setPublisherStreamReady,
  selectedAudioInput,
  selectedVideoInput,
  selectedAudioOutput,
  setAudioInput,
  setVideoInput,
  setAvailableAudioOutputs,
  setAudioOutput,
  audioActive,
  videoActive,
  addPublisherStreamToAttendees,
  setPublisherElement,
} from '../store/session';
import { displayName } from '../store/user';
import OT, { OTError, Publisher, PublisherProperties } from '@opentok/client';
import { setFocusViewElement } from '../store/views';

export default class PublisherAdapter extends Publisher {
  private publisher: Publisher;
  private screenSharePublisher: Publisher;

  constructor() {
    super();
  }

  public initPublisher() {
    if (this.publisher) {
      this.publisher.destroy();
    }
    const publisherProperties: PublisherProperties = {
      insertMode: 'append',
      publishAudio: audioActive.value,
      publishVideo: videoActive.value,
      resolution: '1280x720',
      height: '100%',
      width: '100%',
      showControls: false,
      name: displayName.value,
      insertDefaultUI: false,
      fitMode: 'contain',
    };
    console.log(publisherProperties);
    const callback = (err: OTError): void => {
      if (err) {
        console.error(err);
        return;
      }
      this.publisher = setPublisher(this.publisher);

      if (!callActive.value) {
        this.initInputDevices();
        this.initOutputDevices();
      }
    };
    this.publisher = OT.initPublisher('', publisherProperties, callback);
    this.initPublisherListeners();
  }

  public sessionPublish() {
    sessionPublish({
      screenPublisher: false,
      callback: () => {
        setAudioInput(selectedAudioInput.value);
        setVideoInput(selectedVideoInput.value);
        setAudioOutput(selectedAudioOutput.value);
        setFocusViewElement({ view: 'default', data: {} });
      },
    });
  }

  public initScreenSharePublisher(
    replacementElementId?: string | HTMLElement,
    properties?: OT.PublisherProperties,
    callback?: any,
  ) {
    if (this.screenSharePublisher) {
      this.screenSharePublisher.destroy();
    }
    callback = callback
      ? callback
      : (err) => {
          if (err) {
            console.error(err);
            return;
          }
          setScreenSharePublisher(this.screenSharePublisher);
          if (callActive.value) {
            sessionPublish({
              screenPublisher: true,
              callback: () => {
                setFocusViewElement({ view: 'screen', data: {} });
              },
            });
          }
        };
    properties = properties || {
      videoSource: 'screen',
      insertMode: 'append',
      height: '100%',
      width: '100%',
    };
    this.screenSharePublisher = OT.initPublisher(replacementElementId, properties, callback);
  }

  private initPublisherListeners() {
    this.publisher.on({
      streamCreated: (event) => {
        setPublisherStreamReady(true);
        addPublisherStreamToAttendees();
        console.log('publisher stream created');
      },
      streamDestroyed: (event) => {
        setPublisherStreamReady(false);
        console.log('publisher stream destroyed');
      },
      videoElementCreated: (event) => {
        setPublisherElement(event.element);
        // this occurs on initial load in the lobby view, when the video element is created for the
        // user, we append it to the call preview manually
        document.getElementById('publisher-preview-element').appendChild(event.element);
      },
    });
  }

  public unpublish(screenShare?: boolean) {
    if (screenShare) {
      sessionUnpublish(this.screenSharePublisher);
    } else {
      sessionUnpublish(this.publisher);
    }
  }

  private initInputDevices() {
    let audioInputs;
    let videoInputs;
    if (selectedAudioInput.value?.selected || selectedVideoInput.value?.selected) return;
    OT.getDevices((err, devices) => {
      audioInputs = devices.filter((device) => device.kind === 'audioInput');
      videoInputs = devices.filter((device) => device.kind === 'videoInput');

      audioInputs.forEach((device) => {
        if (device.label === this.publisher.getAudioSource().label) {
          device.selected = true;
        } else {
          device.selected = false;
        }
      });

      videoInputs.forEach((device) => {
        if (device.deviceId === this.publisher.getVideoSource().deviceId) {
          device.selected = true;
        } else {
          device.selected = false;
        }
      });

      setAvailableAudioInputs(audioInputs);
      setAvailableVideoInputs(videoInputs);
    });
  }

  private async initOutputDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let audioOutputs = devices.filter((device) => device.kind === 'audiooutput');

    audioOutputs = audioOutputs.map((device) => {
      device = device.toJSON();
      if (device.deviceId === 'default') {
        (device as any).selected = true;
      } else {
        (device as any).selected = false;
      }
      return device;
    });

    setAvailableAudioOutputs(audioOutputs);
  }
}
