import { getter } from './store';

export const open = getter<boolean>('open', (state) => state.open);

export const goalGroupSelected = getter<string>(
  'goalGroupSelected',
  (state) => state.goalGroupSelected,
);

export const goalGroups = getter('goalGroups', (state) => state.goalGroups);

export const goalGroupsNotEmpty = getter(
  'goalGroupsNotEmpty',
  (state) => !!Object.keys(state.goalGroups).length,
);

export const goalsByGroupSelected = getter(
  'goalsByGroupSelected',
  (state) => state.goalGroups[goalGroupSelected.value],
);
