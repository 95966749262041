import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.width,
    height: _ctx.height,
    viewBox: _ctx.viewBox,
    "aria-labelledby": _ctx.iconName,
    role: "presentation"
  }, [
    _createVNode("title", {
      id: _ctx.iconName,
      lang: "en"
    }, _toDisplayString(_ctx.iconName) + " icon", 9, ["id"]),
    _createVNode("g", {
      fill: _ctx.isHover ? _ctx.iconHoverColor : _ctx.iconColor,
      onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHover = true)),
      onMouseout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isHover = false))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 40, ["fill"])
  ], 8, ["width", "height", "viewBox", "aria-labelledby"]))
}