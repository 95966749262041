import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "menu-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuItem = _resolveComponent("MenuItem")

  return (_openBlock(), _createBlock("div", {
    class: "user-menu",
    onMouseover: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showCloseButton = true)),
    onMouseleave: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCloseButton = false))
  }, [
    _createVNode("div", _hoisted_1, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.menuItems, (item) => {
        return (_openBlock(), _createBlock(_component_MenuItem, {
          key: item.title,
          item: item,
          onClick: ($event: any) => (_ctx.handleMenuItemAction(item.action))
        }, null, 8, ["item", "onClick"]))
      }), 128))
    ])
  ], 32))
}