import { ChatState, MessageType } from '../../utils/Types';

const STATE: ChatState = {
  open: false,
  chatGroupSelected: 'all',
  hasNewMessages: false,
  chatGroups: {
    all: {
      name: 'All',
      messages: [
        {
          messageType: MessageType.Public,
          from: 'SERVER',
          to: 'all',
          messages: [
            // {
            //   message: 'Welcome! Messages here can be read by everyone on the call.',
            //   timestamp: new Date().toLocaleTimeString('en-US'),
            //   seen: false,
            // },
          ],
        },
      ],
      active: true,
    },
  },
};

export default STATE;
