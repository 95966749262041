
import { defineComponent } from 'vue';
import Card from 'primevue/card';
import CameraPicker from '../Pickers/CameraPicker.vue';
import MicPicker from '../Pickers/MicPicker.vue';
import SpeakerPicker from '../Pickers/SpeakerPicker.vue';
import AttendeeForm from '../Forms/AttendeeForm.vue';
import MicSwitch from '../Switches/MicSwitch.vue';
import VideoSwitch from '../Switches/VideoSwitch.vue';
import HostStatus from './components/HostStatus.vue';
import { clinician } from '../../store/user';

export default defineComponent({
  name: 'CallPreview',
  components: {
    Card,
    CameraPicker,
    MicPicker,
    SpeakerPicker,
    AttendeeForm,
    MicSwitch,
    VideoSwitch,
    HostStatus,
  },
  setup() {
    return { clinician };
  },
});
