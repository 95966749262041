
import { defineComponent, computed, ref, onBeforeUnmount, onUpdated, toRef } from 'vue';
import JoinCall from '../CallPreview/components/JoinCall.vue';
import { firstName, lastName, setFirstName, setLastName } from '../../store/user';
import { awaitingApproval } from '../../store/session';
import { CensorSensor } from 'censor-sensor';
import list from 'badwords-list';
import ReallyBadWords from 'raw-loader!../../assets/text/bad.txt';

const badWords = list.array;
const reallyBadWords = ReallyBadWords.split(',');
const censor = new CensorSensor();

const ALERT = {
  NOT_ALLOWED: 'word is not allowed',
  EMPTY: 'this field cannot be blank',
  COMBO: 'word combination not allowed',
};

export default defineComponent({
  name: 'AttendeeForm',
  components: { JoinCall },
  props: {
    showSubmit: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const lNameAlert = ref(null);
    const fNameAlert = ref(null);
    const originalLName = ref(lastName.value);
    const originalFName = ref(firstName.value);
    const showSubmit = toRef(props, 'showSubmit');

    const debounce = (func: any, wait: number) => {
      let timeout;
      return function(this: any, ...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, wait);
      };
    };

    const debouncedSetFirstName = debounce(setFirstName, 500);
    const debouncedSetLastName = debounce(setLastName, 500);

    /**
     * check the value coming in for first name for
     * naughty words and alert the user and block the 'ask to join' if so
     * @param val:string
     * @return void
     */
    const checkAndSetFirstName = (val: string) => {
      checkFirstName(val);
      debouncedSetFirstName(val);
    };

    /**
     * check the value coming in for last name for
     * naughty words and alert the user and block the 'ask to join' if so
     * @param val:string
     * @return void
     */
    const checkAndSetLastName = (val: string): void => {
      checkLastName(val);
      debouncedSetLastName(val);
    };

    /**
     * absolutely do not include these words
     * @return boolean
     */
    const containsAbsolutelyNotWords = (val: string): boolean => {
      return reallyBadWords.some((word) => {
        console.log(val.includes(word.trim()));
        return val.includes(word.trim());
      });
    };
    /**
     * check last name for naughty bits
     * @param val:string
     * @return void
     */
    const checkLastName = (val): void => {
      censor.isProfane(val) || containsAbsolutelyNotWords(val)
        ? (lNameAlert.value = ALERT.NOT_ALLOWED)
        : (lNameAlert.value = null);
    };

    /**
     * check first name for naughty bits
     * @param val:string
     * @return void
     */
    const checkFirstName = (val): void => {
      censor.isProfane(val) || containsAbsolutelyNotWords(val)
        ? (fNameAlert.value = ALERT.NOT_ALLOWED)
        : (fNameAlert.value = null);
    };

    /**
     * on update check both names combined for sneaky word combos
     * @return boolean
     */
    const checkNameCombo = (): boolean => {
      const firstLastName = firstName.value + lastName.value;

      if (censor.isProfane(firstLastName)) {
        //if there isn't already a fname alert then set one
        if (!fNameAlert.value) {
          fNameAlert.value = ALERT.COMBO;
        }

        //if there isn't already a lname alert then set one
        if (!lNameAlert.value) {
          lNameAlert.value = ALERT.COMBO;
        }

        return true;
      }

      return false;
    };

    /**
     * check if the JOIN button should be disabled
     * @return boolean
     */
    const shouldDisable = (): boolean => {
      return (
        fNameAlert.value !== null || lNameAlert.value !== null || formEmpty() || checkNameCombo()
      );
    };

    /**
     * check if form is empty
     * @returns boolean
     */
    const formEmpty = (): boolean => {
      const isEmpty = !fname.value || !lname.value;
      !fname.value ? (fNameAlert.value = ALERT.EMPTY) : (fNameAlert.value = null);
      !lname.value ? (lNameAlert.value = ALERT.EMPTY) : (lNameAlert.value = null);

      return isEmpty;
    };

    const fname = computed({
      get: () => firstName.value,
      set: (value) => checkAndSetFirstName(value),
    });

    const lname = computed({
      get: () => lastName.value,
      set: (value) => checkAndSetLastName(value),
    });

    /**
     * add more robust swears and letter combos
     * @return void
     */
    const addMoreBadWords = () => {
      badWords.forEach((word) => {
        censor.addWord(word);
      });
    };

    //this happens on 'beforeCreate' and only run once
    addMoreBadWords();

    // ----- lifecycle hooks ----- //

    /**
     * before unmounting if there is any unallowed names
     * change back to original on close of settings panel
     */
    onBeforeUnmount(() => {
      if (shouldDisable()) {
        setFirstName(originalFName.value);
        setLastName(originalLName.value);
      }
    });

    /**
     * on next cycle check combo words
     */
    onUpdated(() => {
      checkNameCombo();
    });

    return {
      fname,
      fNameAlert,
      lNameAlert,
      lname,
      formEmpty,
      shouldDisable,
      awaitingApproval,
      showSubmit,
    };
  },
});
