import { getter } from './store';

export const attendees = getter('attendees', (state) => state.attendees);

export const attendeeCount = getter('attendeeCount', (state) => state.attendeeCount);

export const pendingAttendees = getter('pendingAttendees', (state) => state.pendingAttendees);

export const pendingAttendeeCount = getter(
  'pendingAttendeeCount',
  (state) => state.pendingAttendeeCount,
);

export const connectedAttendees = getter('connectedAttendees', (state) => state.connectedAttendees);

export const connectedAttendeeCount = getter(
  'connectedAttendeeCount',
  (state) => state.connectedAttendeeCount,
);

export const lobbyUserCount = getter(
  'lobbyUserCount',
  (state) => state.connectedAttendeeCount - (state.attendeeCount + state.pendingAttendeeCount),
);

export const focusedAttendee = getter('focusedAttendee', (state) => {
  return Object.values(state.attendees).find((attendee) => attendee.focused);
});
