
import { defineComponent, toRefs } from 'vue';
export default defineComponent({
  name: 'Modal',
  emits: ['close'],
  props: {
    headerClass: {
      type: String,
    },
    modalClass: {
      type: String,
    },
    bodyClass: {
      type: String,
    },
    footerClass: {
      type: String,
    },
    closeButtonClass: {
      type: String,
    },
  },
  setup(props) {
    const { headerClass, modalClass, bodyClass, footerClass, closeButtonClass } = toRefs(props);

    return {
      headerClass,
      modalClass,
      bodyClass,
      footerClass,
      closeButtonClass,
    };
  },
});
