import { toRaw } from 'vue';

import { ChatGroup, ChatMessage, ChatMessageGroup, MessageType } from '../../utils/Types';

import { mutation } from './store';
import { streams, session } from '../session';
import { displayName } from '../user';
import { attendees } from '../attendees';

export const toggleChatWindow = mutation('toggleChatWindow', (state, payload: boolean) => {
  state.open = payload;
});

/**
 * set the sub chat group to selected
 */
export const setChatGroupSelected = mutation('setChatGroupSelected', (state, payload: string) => {
  state.chatGroupSelected = payload;
});

/**
 * reset all active groups to false before setting the one selected
 */
export const resetAllActiveGroups = mutation('resetAllActiveGroups', (state) => {
  Object.keys(state.chatGroups).forEach((key) => {
    state.chatGroups[key].active = false;
  });
});

/**
 * update the recipients new message count for notifications
 */
export const updateNewMessageCount = mutation('updateNewMessageCount', (state, from: string) => {
  const myId = session.value.connection?.connectionId;
  const senderIsMe = from === myId;
  //if recipient then keep going
  if (!senderIsMe) {
    getTotalUnreadMessageCount();
  }
});

/**
 * get the total unread messages on any message sent update or when user switches sub chats
 */
export const getTotalUnreadMessageCount = mutation('getTotalUnreadMessageCount', (state) => {
  //total count of unread message
  let totalCount = 0;
  //loop through all the chat groups and sub messages and get the unread messages
  Object.keys(state.chatGroups).forEach((key) => {
    //count of each chat channel messages
    let count = 0;
    //more loops
    state.chatGroups[key].messages.forEach((message) => {
      //even more loops
      message.messages.forEach((msg) => {
        if (!msg.seen) {
          count++;
          totalCount++;
        }
      });
    });

    state.chatGroups[key].newMessageCount = count;
    //if all the messages have been read then reset the main notification to off
    if (totalCount === 0) {
      state.hasNewMessages = false;
    } else {
      state.hasNewMessages = true;
    }
  });
});

/**
 * set the selected group to active
 */
export const setActiveGroupSelected = mutation('setActiveGroupSelected', (state) => {
  state.chatGroups[state.chatGroupSelected].active = true;
});

export const setHasNewMessages = mutation('setHasNewMessages', (state, payload: boolean) => {
  state.hasNewMessages = payload;
});

/**
 * set the chat group that is selected to seen messages
 */
export const setChatGroupMessagesToSeen = mutation('setChatGroupMessagesToSeen', (state) => {
  state.chatGroups[state.chatGroupSelected].messages.forEach((message) => {
    message.messages.forEach((subMessage) => {
      subMessage.seen = true;
    });
  });
  state.chatGroups[state.chatGroupSelected].newMessageCount = 0;
});

/**
 * change the chat display name to first name and first initial of last name
 * @param val
 * @returns String
 */
const truncateName = (val) => {
  const splitName = val.split(' ');
  if (splitName.length > 1) {
    return splitName[0] + ' ' + splitName[1].charAt(0) + '.';
  }
  return val;
};

/**
 * add a new chat group
 */
export const addNewChatGroup = mutation(
  'addNewChatGroup',
  (state, { id, name, color }: { id: string } & Pick<ChatGroup, 'name' | 'color'>) => {
    const displayName = name !== 'All' ? truncateName(name) : name;
    state.chatGroups[id] = {
      name: displayName,
      color,
      active: true,
      newMessageCount: 0,
      messages: [],
    };
  },
);

/**
 * add a new chat message
 */
export const addNewChatMessage = mutation(
  'addNewChatMessage',
  (
    state,
    {
      from,
      to,
      message,
      messageType,
    }: Pick<ChatMessageGroup, 'messageType' | 'from' | 'to'> & Pick<ChatMessage, 'message'>,
  ) => {
    let messages = null;
    const myId = session.value.connection?.connectionId;
    const senderIsMe = from === myId;
    //if the sender is ME then set my message to seen else set to NOT SEEN
    let seen = senderIsMe ? true : false;

    if (messageType === MessageType.Public) {
      messages = state.chatGroups['all'].messages;
    } else {
      const chatGroupId = from === myId ? to : from;
      messages = state.chatGroups[chatGroupId].messages;
    }
    if (messages.length) {
      const lastIndex = messages.length - 1;
      const lastSenderSameAsCurrent = messages[lastIndex].from === from;

      if (lastSenderSameAsCurrent) {
        messages[lastIndex].messages.push({
          message,
          timestamp: new Date().toLocaleTimeString('en-US'),
          seen: seen,
        });

        return;
      }
    }
    let name = senderIsMe ? displayName.value : truncateName(attendees.value[from].name);

    messages.push({
      name,
      messageType,
      from,
      to,
      messages: [{ message, timestamp: new Date().toLocaleTimeString('en-US'), seen: seen }],
    });
  },
);

export const sendChatMessage = mutation(
  'sendChatMessage',
  (state, { to, message }: Pick<ChatMessageGroup, 'to'> & Pick<ChatMessage, 'message'>) => {
    let payload = {};
    if (to) {
      const recipientConnection = streams.value[attendees.value[to].streamId].connection;
      payload = { to: recipientConnection, data: message, type: 'private_message' };
    } else {
      payload = { data: message, type: 'public_message' };
    }

    toRaw(session.value).signal(payload, (error) => {
      if (error) {
        console.log(`message not sent (${error.name}): ${error.message}`);
      } else {
        console.log(`message sent to: ${to}`);
        if (to) {
          addNewChatMessage({
            from: session.value.connection?.connectionId,
            to,
            message,
            messageType: MessageType.Private,
          });
        }
      }
    });
  },
);
