import { getter } from './store';
import { clinician } from '../user';

export const session = getter('session', (state) => state.session);

// Current user's connectionId
export const connectionId = getter(
  'connectionId',
  (state) => state.session.connection?.connectionId,
);

export const publisherStreamReady = getter(
  'publisherStreamReady',
  (state) => state.publisherStreamReady,
);

export const callActive = getter('callActive', (state) => state.callActive);

export const approvedByClinician = getter('approvedByClinician', (state) => {
  if (clinician.value) {
    return true;
  }
  return state.approvedByClinician;
});

export const awaitingApproval = getter('awaitingApproval', (state) => {
  return !approvedByClinician.value && callActive.value;
});

export const joinDisabled = getter('joinDisabled', (state) => state.joinDisabled);

export const connected = getter('connected', (state) => state.connected);

export const streams = getter('streams', (state) => state.streams);

export const audioInputs = getter('audioInputs', (state) => state.audioInputs);

export const audioOutputs = getter('audioOutputs', (state) => state.audioOutputs);

export const videoInputs = getter('videoInputs', (state) => state.videoInputs);

export const selectedAudioInput = getter('selectedAudioInput', (state) => {
  return state.audioInputs && state.audioInputs.filter((audioInput) => audioInput.selected)[0];
});

export const selectedAudioOutput = getter('selectedAudioOutput', (state) => {
  return state.audioOutputs && state.audioOutputs.filter((audioOutput) => audioOutput.selected)[0];
});

export const selectedVideoInput = getter('selectedVideoInput', (state) => {
  return state.videoInputs && state.videoInputs.filter((videoInput) => videoInput.selected)[0];
});

export const publisher = getter('publisher', (state) => state.publisher);

export const publisherElement = getter('publisher-element', (state) => state.publisherElement);

export const screenSharePublisher = getter(
  'screenSharePublisher',
  (state) => state.screenSharePublisher,
);

export const screenShareStream = getter('screenShareStream', (state) => state.screenShareStream);

export const videoActive = getter('videoActive', (state) => state.videoActive);

export const audioActive = getter('audioActive', (state) => state.audioActive);

export const hostConnected = getter('hostConnected', (state) => state.hostConnected);
