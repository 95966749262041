import { mutation } from './store';
import { toRaw, markRaw } from 'vue';
import { Session, Stream, Publisher, OTError, Subscriber, Connection } from '@opentok/client';
import { AttendeeInfo, AudioInput, AudioOutput, VideoInput } from '../../utils/Types';
import { addAttendee } from '../attendees/mutations';
import { userId, displayName, firstName, lastName } from '../user';
import { attendees } from '../attendees';
import { addNewChatGroup } from '../chat';
import { setScreenShareActive } from '../views';
import { publisher } from './getters';

//
// Connection/Call status
//

/**
 * Sets call status to active to enable certain call features.
 * @category SessionMutations
 * @function setCallActive(active:boolean)
 * @param {boolean} active - Whether the call is active.
 */
export const setCallActive = mutation('callActive', (state, active: boolean) => {
  state.callActive = active;
});

export const setApprovedByClinician = mutation(
  'setApprovedByClinician',
  (state, approved: boolean) => {
    state.approvedByClinician = approved;
  },
);

export const setHostStatus = mutation('setHostStatus', (state, status: boolean) => {
  state.hostConnected = status;
});

/**
 * Sets connected status to true while signaling to other users their name and userId.
 * This occurs when a user first logs in to the application and enters the lobby.
 * @category SessionMutations
 * @function setSessionConnected
 */
export const setSessionConnected = mutation('setSessionConnected', (state) => {
  state.connected = true;
  const userData = {
    userId: userId.value,
    displayName: displayName.value,
    firstName: firstName.value,
    lastName: lastName.value,
    connectionId: state.session.connection?.connectionId,
  };
  toRaw(state.session).signal(
    { type: 'attendee_connected', data: JSON.stringify(userData) },
    (e) => {
      console.log(e);
    },
  );
});

/**
 * Sets connected status to false.
 * This occurs when a user presses the end call button.
 * @category SessionMutations
 * @function setSessionDisconnected
 */
export const setSessionDisconnected = mutation('setSessionDisconnected', (state) => {
  state.connected = false;
  if (!state.session.connection) {
    return;
  }
  state.session.disconnect();
});

/**
 * Sets the session object for use within state.
 * @category SessionMutations
 * @function setSessionObject
 * @param {Session} session The opentok session object.
 */
export const setSessionObject = mutation('setSessionObject', (state, session: Session) => {
  state.session = session;
  return state.session;
});

/**
 * @private
 * @deprecated
 */
export const setJoinDisabled = mutation('setJoinDisabled', (state, payload: boolean) => {
  state.joinDisabled = payload;
});

/**
 * Publisher
 * Any mutations related to the current user's video or audio streams.
 */

/**
 * Unpublishes the specified publisher from the session.
 * @category SessionMutations
 * @function sessionUnpublish
 * @param {Publisher} publisher The publisher to be unpublished.
 */
export const sessionUnpublish = mutation('sessionUnpublish', (state, publisher: Publisher) => {
  publisher.destroy();
});

/**
 * Toggles the publisherStreamReady getter to true or false.
 * @category SessionMutations
 * @function setPublisherStreamReady
 * @param {boolean} ready Is the publisher stream ready.
 */
export const setPublisherStreamReady = mutation(
  'setPublisherStreamReady',
  (state, ready: boolean) => {
    state.publisherStreamReady = ready;
  },
);

/**
 * Sets whether the current client's video is currently on.
 * @category SessionMutations
 * @function setVideoState
 * @param {boolean} enabled Is the video feed active.
 */
export const setVideoState = mutation('setVideoState', (state, enabled: boolean) => {
  toRaw(state.publisher).publishVideo(enabled);
  state.videoActive = enabled;
});

/**
 * Sets whether the current client's microphone is currently on.
 * @category SessionMutations
 * @function setMicrophoneState
 * @param {boolean} enabled Is the microphone input active.
 */
export const setMicrophoneState = mutation('setMicrophoneState', (state, enabled: boolean) => {
  toRaw(state.publisher).publishAudio(enabled);
  state.audioActive = enabled;
});

/**
 * Sets the current client's available audio inputs.
 * @category SessionMutations
 * @function setAvailableAudioInputs
 * @param {AudioInput[]} audioInputs Audio inputs available to publish.
 */
export const setAvailableAudioInputs = mutation(
  'setAvailableAudioInputs',
  (state, audioInputs: AudioInput[]) => {
    state.audioInputs = audioInputs;
  },
);

/**
 * Sets the current client's available audio outputs.
 * @category SessionMutations
 * @function setAvailableAudioOutputs
 * @param {AudioOutput[]} audioOutputs Audio outputs available to subscribe with.
 */
export const setAvailableAudioOutputs = mutation(
  'setAvailableAudioOutputs',
  (state, audioOutputs: AudioOutput[]) => {
    state.audioOutputs = audioOutputs;
  },
);

/**
 * Sets the current client's available video inputs.
 * @category SessionMutations
 * @function setAvailableVideoInputs
 * @param {VideoInput[]} videoInputs Video inputs available to publish.
 */
export const setAvailableVideoInputs = mutation(
  'setAvailableVideoInputs',
  (state, videoInputs: VideoInput[]) => {
    state.videoInputs = videoInputs;
  },
);

/**
 * Sets the video input to use when publishing.
 * @category SessionMutations
 * @function setVideoInput
 * @param {VideoInput[]} videoInput Video input user wants to publish.
 */
export const setVideoInput = mutation('setVideoInput', (state, videoInput: VideoInput) => {
  state.videoInputs.forEach((input) => {
    if (videoInput.deviceId == input.deviceId) {
      input.selected = true;
      state.publisher.setVideoSource(input.deviceId);
    } else {
      input.selected = false;
    }
  });
});

/**
 * Sets the audio input to use when publishing.
 * @category SessionMutations
 * @function setAudioInput
 * @param {AudioInput[]} audioInput Audio input user wants to publish.
 */
export const setAudioInput = mutation('setAudioInput', (state, audioInput: AudioInput) => {
  state.audioInputs.forEach((input) => {
    if (audioInput.deviceId == input.deviceId) {
      input.selected = true;
      toRaw(state.publisher).setAudioSource(input.deviceId);
    } else {
      input.selected = false;
    }
  });
});

/**
 * Sets the audio output to use when subscribing to streams.
 * @category SessionMutations
 * @function setAudioOutput
 * @param {AudioOutput[]} audioOutput Audio output user wants to listen with.
 */
export const setAudioOutput = mutation('setAudioOutput', (state, audioOutput: AudioOutput) => {
  state.audioOutputs.forEach((output) => {
    if (audioOutput.deviceId == output.deviceId) {
      output.selected = true;
    } else {
      output.selected = false;
    }
  });
});

/**
 * Set publisher to be stored in state.
 * @category SessionMutations
 * @function setPublisher
 * @param {Publisher} publisher Publisher object to store in state.
 */
export const setPublisher = mutation('setPublisher', (state, publisher: Publisher) => {
  state.publisher = publisher;
  return state.publisher;
});

export const setPublisherElement = mutation(
  'set-publisher-element',
  (state, publisherElement: HTMLVideoElement) => {
    state.publisherElement = publisherElement;
  },
);

/**
 * Set screen share publisher to be stored in state.
 * @category SessionMutations
 * @function setScreenSharePublisher
 * @param {Publisher} screenSharePublisher Screen share publisher object to store in state.
 */
export const setScreenSharePublisher = mutation(
  'setScreenSharePublisher',
  (state, screenSharePublisher: Publisher) => {
    state.screenSharePublisher = markRaw(screenSharePublisher);
  },
);

/**
 * Subscriber
 * Any mutations related to others joining a call.
 * @typedef { import("@opentok/client").Subscriber } Subscriber
 */

/**
 * Subscribe to another participants audio and video
 * @category SessionMutations
 * @function sessionSubscribe
 * @param {string} streamId The id of the stream to be subscribed to.
 * @param {HTMLElement} element The element we want to render video to.
 * @param {SubscriberProperties} properties Properties to use for the subscription.
 * @param {number} [order] Order to place the subscription within the attendee drawer, not currently used.
 * @return {Subscriber}
 */
export const sessionSubscribe = mutation(
  'sessionSubscribe',
  (state, { streamId, properties, callback }): Subscriber => {
    const stream: Stream = state.streams[streamId] || state.screenShareStream;
    return toRaw(state.session).subscribe(toRaw(stream), null, properties, callback);
  },
);

/**
 * Adds a stream to a participants state to be subscribed to.
 * Only used by particiapnts, not the host.
 * Called after a host has signaled the stream info to the participants.
 * @category SessionMutations
 * @function addStreamById
 * @param {string} streamId Id of the stream we want to add to our stream map.
 * @param {AttendeeInfo} attendee Attendee info to add to state.
 */
export const addStreamById = mutation(
  'addStreamById',
  (state, { streamId, attendee }: { streamId: string; attendee: AttendeeInfo }) => {
    //@ts-ignore
    const stream = toRaw(state.session).streams.get(streamId);
    if (publisher.value.stream.streamId !== streamId) {
      state.streams[stream.streamId] = stream;
    }
    if (attendee) {
      attendee.streamId = streamId;
      addAttendee(attendee);
      if (attendee.connectionId !== state.session.connection.connectionId) {
        addNewChatGroup({ id: attendee.connectionId, name: attendee.name });
      }
    }
  },
);

/**
 * Sets aside any special streams devoted to screen sharing.
 * @category SessionMutations
 * @function setScreenShareStream
 * @param {Stream | null} stream Stream to set as the screen share stream.
 */
export const setScreenShareStream = mutation(
  'setScreenShareStream',
  (state, stream: Stream | null) => {
    if (stream) {
      state.screenShareStream = stream;
      setScreenShareActive(true);
    } else {
      state.screenShareStream = null;
      setScreenShareActive(false);
    }
  },
);

/**
 * Removes a stream from state if a user disconnects.
 * @category SessionMutations
 * @function removeStreamById
 * @param {string} id Id of stream we want to remove.
 */
export const removeStreamById = mutation('removeStreamById', (state, id: string) => {
  delete state.streams[id];
});

/**
 * Helper to fetch the hosts stream object.
 * @category SessionMutations
 * @param {SessionState} state The session state
 * @returns {Stream}
 */
export const createModeratorStreamObject = (state) => {
  //@ts-ignore
  return toRaw(state.session).streams.get(state.publisher.stream.streamId);
};

/**
 * Helper to initialize the attendee dictionary if
 * there are no attendees currently added to the call.
 * @category SessionMutations
 * @param {Stream} moderatorStream Stream of the host to use if no attendees currently exist.
 */
export const checkIfNoAttendees = (moderatorStream: Stream) => {
  const attendeesObjectEmpty = !Object.keys(attendees.value).length;
  // If attendees object has no properties, we need to add the moderator
  // to send on to the other attendees when they are approved to join
  if (attendeesObjectEmpty) {
    addAttendee({
      index: 0,
      streamId: moderatorStream.streamId,
      connectionId: moderatorStream.connection.connectionId,
      name: moderatorStream.name,
      color: '#000',
    });
  }
};

/**
 * Helper that sends all active streams to a newly approved
 * call participant
 * @category SessionMutations
 * @param {SessionState} state Session state
 * @param {{type: string, data: string, to: Connection}} payload Signal data to be sent to newly admitted particiapnt
 */
export const sendAllStreamsToNewParticipant = (
  state,
  payload: { type: string; data: string; to: Connection },
) => {
  toRaw(state.session).signal(payload, (e: OTError) => {
    console.log(e);
  });
};

/**
 * Helper that sends the newly admitted participant stream and info
 * to all active participants in the call
 * @category SessionMutations
 * @param {SessionState} state Session state
 * @param {{streams: Stream, attendees: AttendeeInfo}} payload Signal data to be sent to active participants.
 */
export const sendApprovedStreamToAllParticipants = (
  state,
  payload: { streams: Stream; attendees: AttendeeInfo },
) => {
  Object.keys(state.streams).forEach((key) => {
    toRaw(state.session).signal(
      {
        type: 'attendee_approved',
        data: JSON.stringify(payload),
        to: state.streams[key].connection,
      },
      (e: OTError) => {
        console.log(e);
      },
    );
  });
};
