
import { defineComponent, toRefs, computed } from 'vue';
import { setCallActive, callActive, joinDisabled, awaitingApproval } from '../../../store/session';
import { setUserType, clinician } from '../../../store/user';
import ProgressSpinner from 'primevue/progressspinner';

export default defineComponent({
  name: 'ToggleCall',
  components: {
    ProgressSpinner,
  },
  props: {
    shouldDisable: Boolean,
  },
  setup(props) {
    const { shouldDisable } = toRefs(props);
    const joinString = computed(() => {
      if (clinician.value) {
        return 'Join';
      }
      return awaitingApproval.value ? 'Asking to join' : 'Ask to join';
    });

    const startCall = () => {
      if (joinDisabled.value) return;
      setUserType();
      setCallActive(true);
    };

    return {
      startCall,
      callActive,
      shouldDisable,
      clinician,
      awaitingApproval,
      joinString,
    };
  },
});
