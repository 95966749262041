import { UpdateEventMode, UpdateEventType, UpdateItem } from '../../utils/Types';
import { STATE as state } from './state';
import { connected, session } from '../session';
import { changeColor } from './mutations';
import { userId } from '../../store/user';
import { ref, toRaw, toRef } from 'vue';
import {
  updateWhiteboardPaperView,
  resetHistory,
  clearWhiteboardProject,
  clearHistoryByConnectionId,
  updateHistory,
} from './mutations';

const updateTimeout = ref(null);
const count = ref(0);
const updateTimeoutDuration = 250;

export const clearUserHistory = (id: string) => {
  clearHistoryByConnectionId(id);

  if (connected) {
    session.value.signal(
      {
        type: 'otWhiteboard_clear_user',
        data: id,
      },
      (e) => console.log,
    );
  }
};

export const sendUpdate = (type: string, update: UpdateItem | string, toConnection?: string) => {
  const { batchUpdates } = state;
  if (connected.value) {
    batchUpdates.push(update);

    if (!updateTimeout.value) {
      updateTimeout.value = setTimeout(() => {
        batchSignal<string | UpdateItem>(type, batchUpdates, toConnection);
        batchUpdates.length = 0;
        updateTimeout.value = null;
      }, updateTimeoutDuration);
    }
  }
};

export const batchSignal = <Type>(type: string, data: Type[], toConnection?: string) => {
  let dataCopy = data.slice();
  const signalError = (err) => {
    if (err) {
      console.error(err);
    }
  };
  while (dataCopy.length) {
    const dataChunk = dataCopy.splice(0, Math.min(dataCopy.length, 32));
    const signal = {
      type: type,
      data: JSON.stringify(dataChunk),
      // to: toConnection ? toConnection : null
    };
    session.value.signal(signal, signalError);
  }
};

export const clearBroadcast = () => {
  clearCanvas();
  if (connected.value) {
    session.value.signal(
      {
        type: 'otWhiteboard_clear',
      },
      (e) => console.log,
    );
  }
};

export const clearCanvas = () => {
  const { pathStack, textStack, eraseStack } = state;

  clearWhiteboardProject();
  updateWhiteboardPaperView();
  resetHistory();

  pathStack.length = 0;
  textStack.length = 0;
  eraseStack.length = 0;
  count.value = 0;
};

export const draw = (update: UpdateItem): void => {
  let { whiteboardPaper, pathStack, eraseStack, textStack } = state;

  updateHistory({ userId: userId.value, value: toRaw(update) });
  const canvasProps = {
    captureText: '',
    strokeCap: 'round',
    strokeJoin: 'round',
    lineWidth: 2,
  };

  whiteboardPaper = toRaw(whiteboardPaper);
  switch (update.event) {
    case 'start':
      let start = new whiteboardPaper.Point(update.fromX, update.fromY);
      if (update.mode === UpdateEventMode.TEXT) {
        let pointText = new whiteboardPaper.PointText({
          point: start,
          fontFamily: 'Courier New',
          fontWeight: 'bold',
          fontSize: 20,
          fillColor: new whiteboardPaper.Color(update.color),
          data: {
            uuid: update.uuid,
            connectionId: update.id,
          },
          selected: true,
        });
        textStack.push(pointText);
        break;
      }
      let path = new whiteboardPaper.Path();

      path.selected = false;
      path.strokeColor = new whiteboardPaper.Color(update.color);
      path.strokeWidth = canvasProps.lineWidth;
      path.strokeCap = canvasProps.strokeCap;
      path.strokeJoin = canvasProps.strokeJoin;
      path.data.uuid = update.uuid;
      path.data.connectionId = update.id;
      path.locked = true;
      path.blendMode = 'source-over';

      if (update.mode === UpdateEventMode.ERASER) {
        path.strokeColor = new whiteboardPaper.Color('transparent');
        path.moveTo(start);
        eraseStack.push(path);
        break;
      }

      path.moveTo(start);

      if (update.visible) {
        path.visible = update.visible;
      }

      pathStack.push(path);
      break;
    case 'drag':
      if (update.mode === UpdateEventMode.ERASER) {
        eraseStack.forEach((path) => {
          if (path.data.uuid === update.uuid) {
            path.add([update.toX, update.toY]);
            Array.from([...pathStack, ...textStack])
              .filter((p) => p.data.connectionId == update.id)
              .forEach((p) => {
                if (!p) return;
                let toDelete =
                  p.className === 'Path'
                    ? (p as paper.Path).getIntersections(path)
                    : (p as paper.PointText).intersects(path);
                if (typeof toDelete === 'boolean') {
                  if (!toDelete) return;
                  p.remove();
                  p.data.removed = true;
                  return;
                }
                toDelete?.forEach((location) => {
                  let temp = (p as paper.Path).splitAt(location);
                  if (p) {
                    if ((p as paper.Path).segments.length <= 2) {
                      p.data.removed = true;
                      p.remove();
                    } else {
                      (p as paper.Path).lastSegment.remove();
                    }
                  }
                  if (temp) {
                    if (temp?.segments.length <= 2) {
                      temp.remove();
                      temp.data.removed = true;
                    } else {
                      temp?.firstSegment.remove();
                      pathStack.push(temp);
                    }
                  }
                });
              });
          }
        });
        break;
      }
      pathStack.forEach((path) => {
        if (path.data.uuid === update.uuid) {
          path.add([update.toX, update.toY]);
        }
      });
      break;
    case 'end':
      state.pathStack = pathStack.filter((path) => !path.data.removed);
      pathStack.forEach((path) => {
        path = toRaw(path);
        if (path.data.uuid === update.uuid) {
          path.simplify();
        }
      });
      eraseStack.length = 0;
      break;
    case 'addtext':
      textStack.forEach((textItem) => {
        if (textItem.data.uuid === update.uuid) {
          textItem.content += update.content;
        }
      });
      break;
    case 'removetext':
      textStack.forEach((textItem) => {
        if (textItem.data.uuid === update.uuid) {
          textItem.content = textItem.content.slice(0, -1);
        }
      });
  }
};

export const drawUpdates = (updates) => {
  updates.forEach((update) => {
    draw(update);
  });
};

export const requestHistory = () => {
  session.value.signal(
    {
      type: 'otWhiteboard_request_history',
    },
    (e) => console.log,
  );
};

export const drawingEvents = (event) => {
  const { client, textToggled, erasing, canvas, color } = state;

  const dragOnlyEvents = ['mousemove', 'touchmove', 'mouseout'];
  if (dragOnlyEvents.includes(event.type) && !client.dragging) {
    return;
  }
  event.preventDefault();

  let { offsetLeft, offsetTop, offsetWidth, offsetHeight } = canvas;
  let scaleX, scaleY, offsetX, offsetY, x, y;
  const typingEvent = event.type === 'keyup' && textToggled;
  if (!typingEvent) {
    scaleX = canvas.width / offsetWidth;
    scaleY = canvas.height / offsetHeight;
    offsetX =
      event.offsetX ||
      event.originalEvent?.pageX - offsetLeft ||
      event.originalEvent?.touches[0]?.pageX - offsetLeft ||
      event.touches[0]?.pageX - offsetLeft ||
      event.changedTouches[0]?.pageX - offsetLeft;
    offsetY =
      event.offsetY ||
      event.originalEvent?.pageY - offsetTop ||
      event.originalEvent?.touches[0].pageY - offsetTop ||
      event.touches[0]?.pageY - offsetTop ||
      event.changedTouches[0]?.pageY - offsetTop;
    x = offsetX * scaleX;
    y = offsetY * scaleY;
  }
  let mode = erasing
    ? UpdateEventMode.ERASER
    : textToggled
    ? UpdateEventMode.TEXT
    : UpdateEventMode.PEN;
  let update: UpdateItem;

  switch (event.type) {
    case 'mousedown':
    case 'touchstart':
      // Start dragging
      client.lastX = x;
      client.lastY = y;
      client.uuid =
        x +
        y +
        Math.random()
          .toString(36)
          .substring(2);
      client.dragging = true;

      if (mode === UpdateEventMode.TEXT) {
        canvas.focus();
        client.dragging = false;
      }

      update = {
        id: session.value?.connection?.connectionId,
        uuid: client.uuid,
        fromX: client.lastX,
        fromY: client.lastY,
        mode: mode as UpdateEventMode,
        color: color,
        event: UpdateEventType.START,
      };

      draw(update);
      sendUpdate('otWhiteboard_update', update);
      break;
    case 'mousemove':
    case 'touchmove':
      x = offsetX * scaleX;
      y = offsetY * scaleY;

      if (client.dragging) {
        // Build update object
        update = {
          id: session.value?.connection?.connectionId,
          uuid: client.uuid,
          fromX: client.lastX,
          fromY: client.lastY,
          toX: x,
          toY: y,
          event: UpdateEventType.DRAG,
          mode: mode as UpdateEventMode,
        };
        count.value++;
        client.lastX = x;
        client.lastY = y;
        draw(update);
        sendUpdate('otWhiteboard_update', update);
      }
      break;
    case 'touchcancel':
    case 'mouseup':
    case 'touchend':
    case 'mouseout':
      if (count.value && !textToggled) {
        update = {
          id: session.value?.connection?.connectionId,
          uuid: client.uuid,
          event: UpdateEventType.END,
          mode: mode as UpdateEventMode,
        };

        draw(update);
        sendUpdate('otWhiteboard_update', update);
        client.dragging = false;
        client.uuid = null;
      }
      break;
    case 'keyup':
      if (textToggled) {
        if (event.key == 'Backspace') {
          update = {
            id: session.value?.connection?.connectionId,
            uuid: client.uuid,
            event: UpdateEventType.REMOVETEXT,
            mode: mode as UpdateEventMode,
          };
        } else if (
          event.key.length === 1 ||
          (event.key.length > 1 && /[^a-zA-Z0-9]/.test(event.key))
        ) {
          update = {
            id: session.value?.connection?.connectionId,
            uuid: client.uuid,
            event: UpdateEventType.ADDTEXT,
            mode: mode as UpdateEventMode,
            content: event.shiftKey
              ? String((event as KeyboardEvent).key)
                  .toString()
                  .toUpperCase()
              : event.key,
          };
        } else {
          break;
        }
        draw(update);
        sendUpdate('otWhiteboard_update', update);
      }
  }
};
