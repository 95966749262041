import { sessionSubscribe, selectedAudioOutput } from '../store/session';
import OT, { Subscriber } from '@opentok/client';
import { getAttendeeByConnectionId, updateAttendee, setAttendeeTalkingStatus } from '../store/attendees';

export default class SubscriberAdapter {
  private subscriber: Subscriber;
  private videoEl: HTMLVideoElement | HTMLObjectElement;
  private activity: { timestamp: number; talking: boolean } = null;

  public subscribe(streamId: string, properties: OT.SubscriberProperties, callback?: any) {
    this.subscriber = sessionSubscribe({
      streamId,
      properties,
      callback: () => {
        if (typeof callback === 'function') {
          callback();
        }
        this.initListeners();
      },
    });
    this.attachSpeakingDetection(this.subscriber);
  }

  public initListeners(): void {
    this.subscriber.on({
      videoElementCreated: (event) => {
        this.videoEl = event.element;
        let {videoEl, connectionId} = getAttendeeByConnectionId(event.target.stream.connection.connectionId);
        videoEl = event.element;
        updateAttendee({videoEl, connectionId});
        this.attachAudioOutputToVideo();
      },
    });
  }

  public attachAudioOutputToVideo(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (typeof (this.videoEl as any).sinkId !== 'undefined') {
        return reject('device does not support setting the audio output');
      } else {
        return (this.videoEl as any)
          .setSinkId(selectedAudioOutput.value.deviceId)
          .then(() => {
            return resolve('successfully set the audio output device');
          })
          .catch((err) => {
            return reject('Failed to set the audio output device');
            // console.error('Failed to set the audio output device ', err);
          });
      }
    });
    // } else {
    //   console.warn('device does not support setting the audio output');
    //   return Promise.reject('device does not support setting the audio output');
    // }
  }

  public attachSpeakingDetection(subscriber: Subscriber) {
    subscriber.on('audioLevelUpdated', this.talkingFunction);
  }


  private talkingFunction(
    event: OT.Event<'audioLevelUpdated', OT.Subscriber> & { audioLevel: number },
  ) {
    let now = Date.now();
    let connectionId = event.target.stream.connection.connectionId;
    if (event.audioLevel > 0.12) {
      if (!this.activity) {
        this.activity = { timestamp: now, talking: false };
      } else if (this.activity.talking) {
        this.activity.timestamp = now;
      } else if (now - this.activity.timestamp > 1000) {
        this.activity.talking = true;
        setAttendeeTalkingStatus({connectionId, talking: true});
      }
    } else if (this.activity && now - this.activity.timestamp > 1500) {
      // detected low audio this.activity for more than 1.5s
      if (this.activity.talking) {
        setAttendeeTalkingStatus({connectionId, talking: false});
      }
      this.activity = null;
    }
  }
}
