
import { defineComponent } from 'vue';
import { clinician } from '../../../store/user';
import { clearBroadcast } from '../../../store/whiteboard';

export default defineComponent({
  name: 'WhiteboardClear',

  setup() {
    return {
      clinician,
      clearBroadcast,
    };
  },
});
