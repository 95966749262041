import { STATE } from './state';
import actionExtension from '@harlem/extension-action';
import { createStore, Extension } from '@harlem/core';
import { AttendeeState } from '@/utils/Types';
// import { markAttendeeAsPresent } from '.';

export const { state, getter, mutation, onAfterMutation, action, ...store } = createStore<
  AttendeeState,
  Extension<AttendeeState>[]
>('attendees', STATE, {
  extensions: [actionExtension()],
  providers: {
    payload: (payload) => payload,
  },
});

// onAfterMutation('addAttendee', async (event) => {
//   await markAttendeeAsPresent(event.payload);
// });
