<template>
  <head>
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/icon?family=Material+Icons+Round" rel="stylesheet" />
  </head>
  <router-view />
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500;700&display=swap');

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url(./fonts/Manrope/Manrope-VariableFont_wght.ttf) format('truetype');
}

html {
  background-color: $light-gray;
}

body {
  margin: 0;
}

#app {
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  padding: 0 12px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: $primary;
}

.row {
  width: 100%;

  .two-column {
    width: 50%;
    height: 100%;
    float: left;
  }
  .two-column:first-of-type {
    border-right: 2px solid $light-gray;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.rectangle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  position: static;
  width: 257px;
  height: 114px;
  left: 12px;
  top: 12px;
  background: #eceeee;
  border-radius: 6px;
  margin-bottom: 12px;
  margin-left: auto;
  margin-right: auto;

  .selector {
    max-width: 19vw;
    height: 100%;
  }
}

.cam {
  height: 78px;
}

.pi {
  color: $primary;
}

.btn-green,
.btn-green-outline {
  border-radius: 5px;
  cursor: pointer;
  width: 60px;
  height: 40px;
  color: white;
  background: #4aae9b;
  border: 2px solid #4aae9b;
  font-family: 'Manrope';
  font-weight: bold;
  font-size: 14px;
}

.btn-green-outline {
  color: #4aae9b;
  background: white;
  border: 2px solid #8bcfcf;
}

.cancel,
.save {
  display: block;
  padding: 5px;
  width: 100px;
  height: 36px;
  margin: 0 12px;
  border-radius: 6px;
  border: none;
  border-bottom: 4px solid $secondary;
}

.cancel {
  border: 1px solid $secondary;
  border-bottom: 4px solid $secondary;
}

.dark {
  color: $dark;
}

.white {
  color: white;
}
</style>
