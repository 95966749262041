
import { toRefs, defineComponent, ref } from 'vue';
import { getAttendeeByConnectionId } from '../../../store/attendees';
import { changeUserColor } from '../../../store/attendees/actions';
import { colors } from '../../../store/whiteboard';
import ColorButton from './ColorButton.vue';

export default defineComponent({
  name: 'ColorPickerModal',
  emits: ['close'],
  components: {
    ColorButton,
  },
  props: {
    color: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const { color, id } = toRefs(props);
    const attendee = ref(getAttendeeByConnectionId(id.value as string));
    const tempColor = ref(color.value);

    const setTempColor = (col) => {
      tempColor.value = col;
    };

    const assignColor = () => {
      attendee.value.color = tempColor.value as string;
      changeUserColor(attendee);
      context.emit('close');
    };

    return {
      color,
      assignColor,
      attendee,
      colors,
      setTempColor,
      tempColor,
    };
  },
});
