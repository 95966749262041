import { WhiteboardColors, WhiteboardState } from '../../utils/Types';

export const STATE: WhiteboardState = {
  canvas: null,
  whiteboardPaper: null,
  history: [],
  pathStack: [],
  textStack: [],
  eraseStack: [],
  batchUpdates: [],
  erasing: false,
  textToggled: false,
  drawing: true,
  color: '#000',
  colors: [
    { color: '#000' },
    { color: '#26728E' },
    { color: '#EDA745' },
    { color: '#7E78D2' },
    { color: '#8BCFCF' },
    { color: '#078888' },
    { color: '#7F3666' },
    { color: '#40B3DD' },
    { color: '#C3370B' },
    { color: '#D49BC1' },
    { color: '#94703C' },
    { color: '#474299' },
  ],
  client: {
    dragging: false,
    lastX: 0,
    lastY: 0,
    uuid: null,
  },
};
