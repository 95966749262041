
import { defineComponent, ref } from 'vue';

import Menu from 'primevue/menu';

import { setVideoState } from '../../store/session';

export default defineComponent({
  name: 'VideoSwitch',
  setup() {
    const enabled = ref(true);

    const toggleVideo = () => {
      enabled.value = !enabled.value;
      setVideoState(enabled.value);
    };

    return {
      enabled,
      toggleVideo,
    };
  },
});
