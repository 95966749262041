

import { defineComponent, toRefs } from 'vue';

type MenuItemProp = {
  title: string
  icon: string,
  action: string,
}

export default defineComponent({
  name: 'MenuItem',
  emits: ['action'],
  props: {
    item: {
      required: true,
      type: Object as () => MenuItemProp
    }
  },
  setup(props) {
    const { item } = toRefs(props);

    return {
      item
    };
  },
});
