import { getter } from './store';

export const open = getter<boolean>('open', (state) => state.open);

export const noteGroupSelected = getter<string>(
  'noteGroupSelected',
  (state) => state.noteGroupSelected,
);

export const noteGroups = getter('noteGroups', (state) => state.noteGroups);

export const noteGroupsNotEmpty = getter(
  'noteGroupsNotEmpty',
  (state) => !!Object.keys(state.noteGroups).length,
);

export const notesByGroupSelected = getter(
  'messagesByGroupSelected',
  (state) => state.noteGroups[noteGroupSelected.value],
);
