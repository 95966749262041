import { getter } from './store';

export const open = getter<boolean>('open', (state) => state.open);

export const chatGroupsSelected = getter<string>(
  'chatGroupSelected',
  (state) => state.chatGroupSelected,
);

export const chatGroups = getter('chatGroups', (state) => state.chatGroups);

export const hasNewMessages = getter('hasNewMessages', (state) => state.hasNewMessages);

export const messagesByGroupSelected = getter(
  'messagesByGroupSelected',
  (state) => state.chatGroups[chatGroupsSelected.value].messages,
);
