import { SessionState, StreamMap } from '../../utils/Types';

const STATE: SessionState = {
  session: null,
  connected: false,
  publisherStreamReady: false,
  connectionCount: 0,
  callActive: false,
  approvedByClinician: false,
  streams: {} as StreamMap,
  publisher: null,
  publisherElement: null,
  screenSharePublisher: null,
  screenShareStream: null,
  audioInputs: null,
  audioOutputs: null,
  videoInputs: null,
  videoActive: true,
  audioActive: true,
  joinDisabled: false,
  hostConnected: false,
};

export default STATE;
