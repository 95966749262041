import { action } from './store';
import { clinician } from '../user';
import {
  checkIfNoAttendees,
  sendAllStreamsToNewParticipant,
  sendApprovedStreamToAllParticipants,
} from './mutations';
import { Publisher, Stream } from '@opentok/client';
import { AttendeeInfo } from '../../utils/Types';
import { addAttendee, attendees } from '../attendees';
import { addNewChatGroup } from '../chat';
import { toRaw } from 'vue';
import { addNoteGroupForAttendee } from '../notes';
import { addGoalGroupForAttendee } from '../goals';

/**
 * Publishes the current clients session to become a subscribable stream.
 * @category SessionMutations
 * @function sessionPublish
 * @param {Object} payload Payload object.
 * @param {Publish} payload.publisher The publisher to be published.
 * @param {PublisherCallback} payload.callback Callback function be called when publishing is complete
 */
export const sessionPublish = action('sessionPublish', async ({ screenPublisher, callback }, mutate) => {
  mutate((state) => {
    const publisher = screenPublisher ? toRaw(state).screenSharePublisher : toRaw(state).publisher;
    toRaw(state).session.publish(publisher, (e) => {
      if (typeof callback === 'function') {
        callback();
      }
      if (clinician.value && !screenPublisher) {
        checkIfNoAttendees(
          toRaw(state).session.streams.get(publisher.value.stream.streamId),
        );
      }
    });
  });
});

export const addPublisherStreamToAttendees = action(
  'addPublisherStreamToAttendees',
  async (undefined, mutate) => {
    mutate((state) => {
      if (clinician.value) {
        checkIfNoAttendees(
          toRaw(state).session.streams.get(toRaw(state).publisher.stream.streamId),
        );
      }
    });
  },
);

/**
 * Subscribes the host to a stream and then sends the stream out to all
 * approved participants in the call. Also sends a signal with all approved streams to
 * the newly added participant.
 *
 * Only used by the host of the call.
 * @category SessionMutations
 * @function addStream
 * @param {Stream} stream Stream approved by host to be admitted to the call.
 * @param { AttendeeInfo } attendeeInfo Info of the attendee added to the call.
 */
export const addStream = action(
  'addStream',
  async ({ stream, attendeeInfo }: { stream: Stream; attendeeInfo: AttendeeInfo }, mutate) => {
    mutate((state) => {
      if (clinician.value) {
        const streamCopy = Object.assign({}, state.streams);
        streamCopy[stream.streamId] = stream;
        const moderatorStream: Stream & { publisher: Publisher } = state.session.streams.get(
          state.publisher.stream.streamId,
        );
        streamCopy[moderatorStream.streamId] = moderatorStream;
        moderatorStream.publisher = null;

        checkIfNoAttendees(moderatorStream);
        addAttendee(attendeeInfo);

        const allStreamsPayload = {
          type: 'attendee_approved_init',
          data: JSON.stringify({ streams: streamCopy, attendees: attendees.value }),
          to: stream.connection,
        };

        addNewChatGroup({ name: attendeeInfo.name, id: attendeeInfo.connectionId });
        addNoteGroupForAttendee({ name: attendeeInfo.name, id: attendeeInfo.connectionId });
        addGoalGroupForAttendee({ name: attendeeInfo.name, id: attendeeInfo.connectionId });

        sendAllStreamsToNewParticipant(state, allStreamsPayload);
        sendApprovedStreamToAllParticipants(state, { streams: stream, attendees: attendeeInfo });
      }
      state.streams[stream.streamId] = stream;
    });
  },
);
