import { AttendeeState } from '@/utils/Types';

export const STATE: AttendeeState = {
  attendees: {},
  pendingAttendees: [],
  connectedAttendees: [],
  attendeeCount: 0,
  pendingAttendeeCount: 0,
  connectedAttendeeCount: 0,
};
