import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

import Notes from './components/Notes/Notes.vue';
import Chat from './components/Chat/Chat.vue';
import Agenda from './components/Agenda.vue';
import Settings from './components/Settings.vue';
import Goals from './components/Goals/Goals.vue';
import { hostMenuOpen, hostMenuSelection } from '../../store/views';
import { noteGroupsNotEmpty } from '../../store/notes';
import { goalGroupsNotEmpty } from '../../store/goals';
import { HostMenuName } from './../../utils/Types';

export default _defineComponent({
  expose: [],
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_unref(hostMenuOpen))
    ? (_openBlock(), _createBlock(_Fragment, { key: 0 }, [
        (_unref(hostMenuSelection) === _unref(HostMenuName).AGENDA)
          ? (_openBlock(), _createBlock(Agenda, { key: 0 }))
          : _createCommentVNode("", true),
        (_unref(hostMenuSelection) === _unref(HostMenuName).NOTES && _unref(noteGroupsNotEmpty))
          ? (_openBlock(), _createBlock(Notes, { key: 1 }))
          : _createCommentVNode("", true),
        (_unref(hostMenuSelection) === _unref(HostMenuName).GOALS && _unref(goalGroupsNotEmpty))
          ? (_openBlock(), _createBlock(Goals, { key: 2 }))
          : _createCommentVNode("", true),
        (_unref(hostMenuSelection) === _unref(HostMenuName).CHAT)
          ? (_openBlock(), _createBlock(Chat, { key: 3 }))
          : _createCommentVNode("", true),
        (_unref(hostMenuSelection) === _unref(HostMenuName).SETTINGS)
          ? (_openBlock(), _createBlock(Settings, { key: 4 }))
          : _createCommentVNode("", true)
      ], 64))
    : _createCommentVNode("", true)
}
}

})