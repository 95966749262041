import STATE from './state';
import actionExtension from '@harlem/extension-action';
import { NotesState, NoteTopics } from '../../utils/Types';
import { updateNotesRequest } from './actions';

import { createStore, Extension } from '@harlem/core';
import { computed } from 'vue';

const debounce = (func: any, wait: number) => {
  let timeout;
  return function(this: any, ...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const {
  state,
  getter,
  mutation,
  onAfterMutation,
  action,
  isActionRunning,
  ...store
} = createStore<NotesState, Extension<NotesState>[]>('notes', STATE, {
  extensions: [actionExtension()],
});

const isRunning = computed(() => isActionRunning('load-user-data'));

const debounceAfterMutation = debounce(updateNotesRequest, 1000);

onAfterMutation('addNoteText', async (event) => {
  await debounceAfterMutation(event.payload);
});
