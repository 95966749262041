import { NotesState, NoteTopics } from '@/utils/Types';
import { action } from './store';

export const updateNotesRequest = (payload) => { };

//TODO for when we have an actual contract to hit in the backend
//action('update-notes-request', (payload, mutate) => {
// const {id, topic, data} = payload;
// mutate((state) => {
// state.noteGroups[id];
// for
// })
// });
