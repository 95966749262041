import { GoalGroup, GoalTypes } from '../../utils/Types';

import { mutation } from './store';

export const toggleGoalWindow = mutation('toggleGoalWindow', (state, payload: boolean) => {
  state.open = payload;
});

/**
 * set the note group to selected
 */
export const setGoalGroupSelected = mutation('setGoalGroupSelected', (state, payload: string) => {
  state.goalGroupSelected = payload;
});

/**
 * change the chat display name to first name and first initial of last name
 * @param val
 * @returns String
 */
const truncateName = (val) => {
  const splitName = val.split(' ');
  if (splitName.length > 1) {
    return splitName[0] + ' ' + splitName[1].charAt(0) + '.';
  }
  return val;
};

/**
 * add a new note group for attendee
 */
export const addGoalGroupForAttendee = mutation(
  'addNoteGroupForAttendee',
  (state, { id, name }: { id: string } & Pick<GoalGroup, 'name'>) => {
    const displayName = truncateName(name);
    const dummyGoalDataArray = [{
      id: 0,
      description: `Define idioms in 4 our of 6 trials given minimal verbal cues as measured by the SLP.`,
      type: GoalTypes.TRIALS,
      maxValue: 6,
      expectedValue: 4,
      actualValue: 1
    }, {
      id: 1,
      description: `Produce /s/ at the word level with 80% accuracy over 3 consecutive session as measured by the SLP.`,
      type: GoalTypes.PERCENTAGE,
      maxValue: 100,
      expectedValue: 80,
      actualValue: 65
    }];
    state.goalGroups[id] = {
      id,
      name: displayName,
      goalData: dummyGoalDataArray,
    };
    if (Object.keys(state.goalGroups).length == 1) {
      setGoalGroupSelected(id);
    }
  },
);

/**
 * add a new note message
 */
export const addGoalData = mutation(
  'addGoalData',
  (state, { id, data }: { id: number; data: number; }) => {
    state.goalGroups[state.goalGroupSelected].goalData[id].actualValue = data;
    // send update to insight
  },
);
