import STATE from './state';
import actionExtension from '@harlem/extension-action';
import { SessionState } from '@/utils/Types';

import { createStore, Extension } from '@harlem/core';

export const { state, getter, mutation, action } = createStore<
  SessionState,
  Extension<SessionState>[]
>('session', STATE, {
  extensions: [actionExtension()],
  providers: {
    read: (state) => state,
    write: (state) => state,
    payload: (payload) => payload,
  },
});
