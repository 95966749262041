import STATE from './state';
import actionExtension from '@harlem/extension-action';
import { GoalsState } from '../../utils/Types';
// import { updateGoalsRequest } from './actions';

import { createStore, Extension } from '@harlem/core';
import { computed } from 'vue';

const debounce = (func: any, wait: number) => {
  let timeout;
  return function(this: any, ...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
};

export const {
  state,
  getter,
  mutation,
  onAfterMutation,
  action,
  isActionRunning,
  ...store
} = createStore<GoalsState, Extension<GoalsState>[]>('goals', STATE, {
  extensions: [actionExtension()],
});

const isRunning = computed(() => isActionRunning('load-user-data'));

// const debounceAfterMutation = debounce(updateGoalsRequest, 1000);

onAfterMutation('addGoalData', async (event) => {
  // await debounceAfterMutation(event.payload);
});
