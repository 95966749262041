
import { defineComponent, onMounted, ref } from 'vue';

import ElapsedTime from './components/ElapsedTime.vue';
import VideoSwitch from './components/VideoSwitch.vue';
import MicSwitch from './components/MicSwitch.vue';
import SpeakerSwitch from './components/SpeakerSwitch.vue';
import LeaveCall from './components/LeaveCall.vue';
import ScreenShareSwitch from './components/ScreenShareSwitch.vue';
import WhiteboardSwitch from './components/WhiteboardSwitch.vue';
import TabMenu from 'primevue/tabmenu';
import { chatGroups } from '../../store/chat';
import Badge from 'primevue/badge';

import { connected, awaitingApproval } from '../../store/session';
import {
  setHostMenuOpen,
  setHostMenuSelection,
  hostMenuSelection,
  hostMenuOpen,
} from '../../store/views';
import { hasNewMessages } from '../../store/chat';

import { clinician } from '../../store/user';
import { HostMenuName } from '../../utils/Types';

export default defineComponent({
  name: 'CallToolbar',
  components: {
    ElapsedTime,
    VideoSwitch,
    MicSwitch,
    SpeakerSwitch,
    ScreenShareSwitch,
    LeaveCall,
    WhiteboardSwitch,
    TabMenu,
    Badge,
  },
  setup() {
    const menuItems = ref([
      {
        name: HostMenuName.AGENDA,
        label: 'summarize',
        admin: true,
        command: () => togglePanel(HostMenuName.AGENDA),
      },
      {
        name: HostMenuName.NOTES,
        label: 'article',
        admin: true,
        command: () => togglePanel(HostMenuName.NOTES),
      },
      {
        name: HostMenuName.GOALS,
        label: 'library_add_check',
        admin: true,
        command: () => togglePanel(HostMenuName.GOALS),
      },
      {
        name: HostMenuName.CHAT,
        label: 'chat_bubble',
        open: open,
        hasNewMessages: hasNewMessages,
        admin: false,
        command: () => togglePanel(HostMenuName.CHAT),
      },

      {
        name: HostMenuName.SETTINGS,
        label: 'settings',
        admin: false,
        command: () => togglePanel(HostMenuName.SETTINGS),
      },
    ]);

    const togglePanel = (menuName: HostMenuName) => {
      if (hostMenuOpen.value && menuName === hostMenuSelection.value) {
        setHostMenuOpen(false);
        return;
      }
      setHostMenuOpen(true);
      setHostMenuSelection(menuName);
    };

    onMounted(() => {
      if (clinician.value) return;
      menuItems.value = menuItems.value.filter((item) => !item.admin);
    });

    return {
      connected,
      awaitingApproval,
      clinician,
      hasNewMessages,
      menuItems,
      hostMenuSelection,
      hostMenuOpen,
    };
  },
});
