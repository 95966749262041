const Home = () => import(/* webpackChunkName: "home" */ './Home.vue');

function dynamicPropsFn(route) {
  let hash = route.hash;
  let tokenSliceIndex = hash.search('&access');
  let meetingIdSliceIndex = hash.search('&state=') + 7;
  let tokenInfo = hash.slice(10, tokenSliceIndex);
  let meetingId = hash.slice(meetingIdSliceIndex);
  return {
    meetingId,
    tokenInfo,
  };
}
module.exports = [
  {
    name: 'home',
    path: '/:tokenInfo(.*)',
    component: Home,
    props: dynamicPropsFn,
  },
];
