
import { defineComponent, watch } from 'vue';
import PublisherAdapter from '../services/PublisherAdapter';
import SessionAdapter from '../services/SessionAdapter';
import FocusView from './FocusView';
import { streams, awaitingApproval, callActive, connected } from '../store/session';
import { clinician, displayName } from '../store/user';
import { apiKey } from '../store/env';

export default defineComponent({
  name: 'session',
  components: {
    FocusView,
  },
  props: {
    sessionId: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
    meetingId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const session = new SessionAdapter(apiKey.value, props.sessionId, props.token);
    const publisher = new PublisherAdapter();
    session.initialize();

    // Here is where all user's publisher stream is initialized when they join the lobby.
    watch([connected], (val) => {
      if (val[0]) {
        publisher.initPublisher();
      }
    });

    watch([displayName, callActive], (newVals, oldVals) => {
      if (newVals[0] !== oldVals[0] && !newVals[1]) {
        publisher.initPublisher();
      }
    });

    // This is where the clinician's(moderator) stream is published when they join the call.
    watch([awaitingApproval, callActive], (newVals) => {
      if (newVals[0] || (newVals[1] && clinician.value)) {
        publisher.sessionPublish();
      }
    });

    const errorHandler = (err) => {
      alert(err.message);
    };

    return {
      streams,
      awaitingApproval,
      callActive,
      errorHandler,
    };
  },
});
