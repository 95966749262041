
import { defineComponent, onMounted, ref, watch } from 'vue';
import { callActive, screenShareStream } from '../../store/session';
import { CallLayouts } from '../../utils/Types';
import { clinician } from '../../store/user';
import {
  whiteboardEnabled,
  focusViewInfo,
  setVideoStatus,
  videoStatus,
  screenShareActive,
  leaveCallPopupOpen,
  callLayout,
} from '../../store/views';
import { pendingAttendeeCount } from '../../store/attendees';
import { Player } from '@techassi/vue-youtube-iframe';
import Whiteboard from '../Whiteboard';
import PublisherAdapter from '../../services/PublisherAdapter';
import SubscriberAdapter from '../../services/SubscriberAdapter';
import ApprovalPopup from './components/ApprovalPopup.vue';
import LeaveCallPopup from '../CallToolbar/components/LeaveCallPopup.vue';
import LayoutPicker from '../Pickers/LayoutPicker.vue';
import AttendeeDrawer from '../AttendeeDrawer/AttendeeDrawer.vue';
import FocusedAttendee from './components/FocusedAttendee.vue';

import Toolbar from '../Whiteboard/components/Toolbar.vue';

export default defineComponent({
  name: 'FocusView',
  components: {
    Whiteboard,
    ApprovalPopup,
    LeaveCallPopup,
    LayoutPicker,
    AttendeeDrawer,
    FocusedAttendee,
    Toolbar,
  },
  setup() {
    const root = ref(null);
    const playerHeight = ref(0);
    const playerWidth = ref(0);
    const videoPlayer = ref(null) as typeof Player;
    const publisherAdapter = ref(null);
    const subscriberAdapter = ref(null);

    const videoStateChange = (e) => {
      if (e.data === 3 || e.data === 1) {
        setVideoStatus({ playing: true, sendSignal: true });
      } else if (e.data === 2) {
        setVideoStatus({ playing: false, sendSignal: true });
      }
    };

    const playerParams = {
      controls: clinician.value ? 1 : 0,
      disablekb: 1,
      modestbranding: 1,
    };

    onMounted(() => {
      playerHeight.value = document.querySelector('#main_focus_viewer').clientHeight;
      playerWidth.value = document.querySelector('#main_focus_viewer').clientWidth;
      publisherAdapter.value = new PublisherAdapter();
      subscriberAdapter.value = new SubscriberAdapter();

      OT.checkScreenSharingCapability(function(response) {
        if (!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing
          console.log('screenshare not supported');
        } else {
          // console.log('screenshare ISSSSSSSSS supported')
        }
      });
    });

    watch(screenShareActive, (newVal, oldVal) => {
      if (clinician.value) {
        if (newVal) {
          publisherAdapter.value.initScreenSharePublisher(root.value);
        } else {
          publisherAdapter.value.unpublish(true);
        }
      }
    });

    watch(screenShareStream, (newVal) => {
      if (newVal) {
        if (!clinician.value) {
          subscriberAdapter.value.subscribe(
            newVal.connection.connectionId,
            root.value,
            { insertMode: 'append', height: '100%', width: '100%', showControls: false },
            newVal.streamId as string,
          );
        }
      }
    });

    watch(videoStatus, (val, oldVal) => {
      if (focusViewInfo.value.view === 'video' && !clinician.value) {
        if (val && !oldVal) {
          videoPlayer.value.playVideo();
        } else {
          videoPlayer.value.pauseVideo();
        }
      }
    });

    return {
      root,
      whiteboardEnabled,
      focusViewInfo,
      videoStateChange,
      playerParams,
      videoPlayer,
      pendingAttendeeCount,
      clinician,
      leaveCallPopupOpen,
      callLayout,
      CallLayouts,
    };
  },
});
