import { action } from './store';
import { Stream } from '@opentok/client';
import { session, streams } from '../session';

export const endMeetingForAll = action('end-meeting-for-all', async (undefined, mutate) => {
  mutate(() => {
    Object.values(streams.value).forEach((stream) => {
      session.value.forceDisconnect((stream as Stream).connection, (e) => console.log);
    });
  });
});
