
import { defineComponent, computed, onMounted } from 'vue';
import { videoInputs, selectedVideoInput, setVideoInput, publisher } from '../../store/session';

import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'CameraPicker',
  components: {
    Dropdown,
  },
  setup() {
    const video = computed({
      get: () => selectedVideoInput.value,
      set: selectedVideoInput => setVideoInput(selectedVideoInput),
    });

    onMounted(() => {
      if (publisher.value) {
        video.value = selectedVideoInput.value;
      }
    });

    return {
      videoInputs,
      video,
    };
  },
});
