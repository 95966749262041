
import { defineComponent } from 'vue';
import { callLayout, setCallLayout } from '../../store/views';
import { CallLayouts } from '../../utils/Types';

export default defineComponent({
  name: 'LayoutPicker',
  setup() {
    return {
      callLayout,
      setCallLayout,
      CallLayouts,
    };
  },
});
