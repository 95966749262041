import { mutation } from './store';
import { session } from '../session';
import { AgendaPreview, NotePreview } from '../../utils/Types';

export const updateAgendaItemById = mutation('updateAgendaItemById', (state, {id, done}: AgendaPreview) => {
  const agendaItem = state.agenda.find(d => d.id == id);
  const nextItemId = id + 1;

  agendaItem.done = done;
  state.agenda.forEach(d => d.active = false);
  agendaItem.active = done ? false : true;
  if (!done) return;

  if (nextItemId < state.agenda.length) {
    const nextItem = state.agenda[nextItemId];
    if (!nextItem.done) {
      nextItem.active = done ? true : false;
    } else {
      findNextAvailableActiveItem();
    }
  } else {
    findNextAvailableActiveItem();
  }

  function findNextAvailableActiveItem() {
    for (let item of state.agenda) {
      if (!item.done) {
        item.active = true;
        break;
      }
    }
  }
});

export const triggerAgendaItem = mutation('triggerAgendaItem', (state, {id, done}: AgendaPreview) => {
  const agendaItem = state.agenda.find(d => d.id == id);
  session.value.signal(
    {
      type: 'agenda',
      data: JSON.stringify(agendaItem)
    },
    error => {
      if (error) {
        console.log(error)
      } else {
        console.log(`${agendaItem.title} started on ${agendaItem.view}`);
        updateAgendaItemById({id, done});
      }
    }
  );
});

export const updateNoteById = mutation('updateNoteById', (state, {id, content}: NotePreview) => {
  const note = state.notes.find(d => d.id == id);
  note.content = content;
});