import { UserState } from '@/utils/Types';

export const STATE: UserState = {
  id: null,
  clinician: false,
  student: false,
  bearerToken: null,
  firstName: '',
  lastName: '',
  email: ''
};