
import { defineComponent, ref } from 'vue';
import { setMicrophoneState } from '../../store/session';

export default defineComponent({
  name: 'MicSwitch',
  setup() {
    const enabled = ref(true);

    const toggleAudio = () => {
      enabled.value = !enabled.value;
      setMicrophoneState(enabled.value);
    };

    return {
      enabled,
      toggleAudio,
    };
  },
});
