
import { Stream } from '@opentok/client';
import { defineComponent, ref, toRefs } from 'vue';
import { addStream } from '../../../store/session';
import { userId, clinician } from '../../../store/user';
import * as whiteboardStore from '../../../store/whiteboard';
import { removePendingAttendee, connectedAttendees } from '../../../store/attendees';

export default defineComponent({
  name: 'PendingAttendee',
  props: {
    stream: {
      type: Stream,
      required: true,
    },
    streamId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { stream } = toRefs(props);
    const attendeeInfo = ref({
      streamId: null,
      connectionId: null,
      name: null,
      color: null,
      userId: null,
    });

    const assignColor = () => {
      const color =
        whiteboardStore.colors.value[
          Math.floor(Math.random() * whiteboardStore.colors.value.length)
        ];
      return color.color;
    };

    const onApprove = (e) => {
      attendeeInfo.value.streamId = (stream as any).value.id;
      attendeeInfo.value.connectionId = (stream as any).value.connection.connectionId;
      attendeeInfo.value.name = (stream as any).value.name;
      attendeeInfo.value.color = assignColor();

      addStream({ stream: stream.value as Stream, attendeeInfo: attendeeInfo.value });
      removePendingAttendee((stream as any).value.streamId);
    };

    const onReject = (e) => {
      // TODO
      // rejectAttendee(stream);
    };

    return {
      onApprove,
      onReject,
      assignColor,
      stream,
      connectedAttendees,
    };
  },
});
