
import { defineComponent } from 'vue';
import { clinician } from '../../../store/user';
import * as whiteboardStore from '../../../store/whiteboard';
import { IconBase, Eraser } from '../../Icons';
import ColorPickerDropdown from './ColorPickerDropdown.vue';

export default defineComponent({
  name: 'Toolbar',
  components: {
    IconBase,
    Eraser,
    ColorPickerDropdown,
  },
  setup() {
    const hoverColor = '#10ACA2';
    const iconColor = '#718484';
    return {
      clinician,
      textToggled: whiteboardStore.textToggled,
      erasing: whiteboardStore.erasing,
      clear: whiteboardStore.clearBroadcast,
      toggleEraser: whiteboardStore.toggleEraser,
      toggleText: whiteboardStore.toggleText,
      toggleDraw: whiteboardStore.toggleDraw,
      drawing: whiteboardStore.drawing,
      iconColor,
      hoverColor,
    };
  },
});
