
import { defineComponent, ref } from 'vue';
import { color, colors, changeColor } from '../../../store/whiteboard';
import Dropdown from 'primevue/dropdown';
import ColorButton from '../../AttendeeDrawer/components/ColorButton.vue';

export default defineComponent({
  name: 'ColorPickerDropdown',
  components: {
    Dropdown,
    ColorButton,
  },
  setup() {
    const setColor = (event) => {
      changeColor(event.value.color);
    };

    return {
      color,
      colors,
      setColor,
    };
  },
});
