
import { toRefs, defineComponent, computed, ref, onUnmounted } from 'vue';
import { AttendeeInfo } from '@/utils/Types';
import { updateAttendee, broadcastAttendeeNameUpdate } from '../../../store/attendees';

export default defineComponent({
  name: 'UserNameFormModal',
  emits: ['close'],
  props: {
    attendee: {
      type: Object,
      required: true,
    },
  },
  setup(props, context) {
    const { attendee } = toRefs(props);
    const firstName = ref(null);
    const lastName = ref(null);

    const fname = computed({
      get: () => firstName.value,
      set: (value) => firstName.value = value
    });

    const lname = computed({
      get: () => lastName.value,
      set: (value) => lastName.value = value
    });

    const saveName = () => {
      let name = fname.value + ' ' + lname.value;
      updateAttendee({ connectionId: attendee.value.connectionId, name });
      broadcastAttendeeNameUpdate({ connectionId: attendee.value.connectionId, name });
      context.emit('close');
    }

    const splitAttendeeName = () => {
      console.log(attendee.value.name)
      const splitName = attendee.value.name.split(" ");
      firstName.value = splitName[0];
      lastName.value = splitName[1];
    }

    splitAttendeeName();

    onUnmounted(() => {
      attendee.value.name = fname.value + ' ' + lname.value;
    })

    return {
      lname, fname, close, saveName, firstName, lastName
    }
  }
});
