
import { defineComponent, onMounted, ref, onBeforeUnmount } from 'vue';
import { session } from '../../store/session';
import { getAttendeeByConnectionId } from '../../store/attendees';
import * as whiteboardStore from '../../store/whiteboard';
import { HistoryItem } from '../../utils/Types';
import Toolbar from './components/Toolbar.vue';
import { clinician } from '../../store/user';

export default defineComponent({
  name: 'Whiteboard',
  components: {
    Toolbar,
  },
  setup() {
    const root = ref(null);
    const drawHistoryReceivedFrom = ref(null);
    const drawHistoryReceived = ref(null);
    const attendee = ref(null);
    const eventsString =
      'mousedown mousemove mouseup mouseout touchstart touchmove touchend touchcancel';
    onMounted(() => {
      whiteboardStore.setupWhiteboardPaper(root.value);
      whiteboardStore.updateWhiteboardPaperView();
      initCanvasListener();
      assignColor();
    });

    onBeforeUnmount(() => {
      removeListeners();
      whiteboardStore.clearCanvas();
    });

    const assignColor = () => {
      if (session.value) {
        attendee.value = getAttendeeByConnectionId(session.value.connection.connectionId);
        whiteboardStore.changeColor(attendee.value.color);
      }
    };

    const initCanvasListener = () => {
      eventsString.split(' ').forEach((e) => {
        root.value.addEventListener(e, whiteboardStore.drawingEvents);
      });
      document.addEventListener('keyup', whiteboardStore.drawingEvents);

      if (session.value) {
        session.value.on({
          sessionConnected: function sessionConnected() {
            whiteboardStore.requestHistory();
          },
          'signal:otWhiteboard_update': (event) => {
            if (event.from.connectionId !== session.value.connection.connectionId) {
              whiteboardStore.drawUpdates(JSON.parse(event.data));
            }
          },
          'signal:otWhiteboard_history': (event) => {
            // We will receive these from everyone in the room, only listen to the first
            // person. Also the data is chunked together so we need all of that person's
            if (
              !drawHistoryReceivedFrom.value ||
              drawHistoryReceivedFrom.value === event.from.connectionId
            ) {
              drawHistoryReceivedFrom.value = event.from.connectionId;
              whiteboardStore.drawUpdates(JSON.parse(event.data));
            }
          },
          'signal:otWhiteboard_clear': (event) => {
            if (event.from.connectionId !== session.value.connection.connectionId) {
              whiteboardStore.clearCanvas();
            }
          },
          'signal:otWhiteboard_clear_user': (event) => {
            whiteboardStore.clearHistoryByConnectionId(event.data);
          },
          'signal:otWhiteboard_request_history': (event) => {
            if (whiteboardStore.history.value.length > 0) {
              whiteboardStore.batchSignal<HistoryItem>(
                'otWhiteboard_history',
                whiteboardStore.history.value as HistoryItem[],
                event.from,
              );
            }
          },
        });
      }
    };

    const removeListeners = () => {
      document.removeEventListener('keyup', whiteboardStore.drawingEvents);
      eventsString.split(' ').forEach((e) => {
        root.value.removeEventListener(e, whiteboardStore.drawingEvents);
      });
      session.value.off(
        `signal:otWhiteboard_update signal:otWhiteboard_history signal:otWhiteboard_clear signal:otWhiteboard_request_history signal:otWhiteboard_add_text sessionConnected`,
      );
    };

    /**
     * set the cursor by the toolbar item selected
     */
    const setByToolbarSelected = () => {
      if (whiteboardStore.erasing.value) {
        return 'grabbing';
      }
      return 'pointer';
    };

    return {
      root,
      setByToolbarSelected,
      clinician,
    };
  },
});
