import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ColorButton = _resolveComponent("ColorButton")
  const _component_Dropdown = _resolveComponent("Dropdown")

  return (_openBlock(), _createBlock(_component_Dropdown, {
    class: "color-dropdown",
    options: _ctx.colors,
    optionLabel: "color",
    placeholder: "Select a Color",
    onChange: _ctx.setColor
  }, {
    value: _withCtx((slotProps) => [
      (_ctx.color)
        ? (_openBlock(), _createBlock(_component_ColorButton, {
            key: 0,
            tempColor: _ctx.color,
            color: _ctx.color,
            showCheckbox: false
          }, null, 8, ["tempColor", "color"]))
        : (_openBlock(), _createBlock("span", _hoisted_1, _toDisplayString(slotProps.placeholder), 1))
    ]),
    option: _withCtx((slotProps) => [
      _createVNode(_component_ColorButton, {
        tempColor: _ctx.color,
        color: slotProps.option.color,
        borderStyle: "2px solid #e9ecef"
      }, null, 8, ["tempColor", "color"])
    ]),
    _: 1
  }, 8, ["options", "onChange"]))
}