/**
 * Modules
 */
import Session from './Session.vue';
import CallToolbar from './CallToolbar';
import CallDrawer from './CallDrawer';
import Sidebar from 'primevue/sidebar';
import CallPreview from './CallPreview';
import Modal from './Modal/Modal.vue';
import BadgeDirective from 'primevue/badgedirective';


export default {
  install(app) {
    app.component('Session', Session);
    app.component('CallToolbar', CallToolbar);
    app.component('CallDrawer', CallDrawer);
    app.component('CallPreview', CallPreview);
    app.component('Sidebar', Sidebar);
    app.component('Modal',Modal);
    app.directive('badge', BadgeDirective);
  }
}