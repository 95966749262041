import { getter } from './store';
import { CallLayouts } from '../../utils/Types';

export const focusViewElement = getter('focusViewElement', state => state.focusViewElement);

export const focusViewInfo = getter('focusViewInfo', state => state.focusViewInfo);

export const whiteboardEnabled = getter('whiteboardEnabled', state => state.whiteboardEnabled);

export const hostMenuOpen = getter('hostMenuOpen', state => state.hostMenuOpen);

export const hostMenuSelection = getter('hostMenuSelection', state => state.hostMenuSelection);

export const videoStatus = getter('videoStatus', state => state.videoStatus);

export const screenShareActive = getter('screenShareActive', state => state.screenShareActive);

export const leaveCallPopupOpen = getter('leaveCallPopupOpen', state => state.leaveCallPopupOpen);

export const callLayout = getter<CallLayouts>('callLayout', state => state.callLayout);