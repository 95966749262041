import { getter } from './store';

export const userId = getter('userId', state => state.id);

export const clinician = getter('clinician', state => state.clinician);

export const student = getter('student', state => state.student);

export const bearerToken = getter('bearerToken', state => state.bearerToken);

export const firstName = getter('firstName', state => state.firstName);

export const lastName = getter('lastName', state => state.lastName);

export const email = getter('email', state => state.email);

export const displayName = getter('displayName', state => `${state.firstName} ${state.lastName}`);