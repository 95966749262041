
import { defineComponent, ref, watch, onUnmounted, onMounted, computed } from 'vue';
import { audioInputs, selectedAudioInput, setAudioInput, publisher } from '../../store/session';

import MicTest from '../../utils/MicTest';
import Dropdown from 'primevue/dropdown';

export default defineComponent({
  name: 'MicPicker',
  components: {
    Dropdown,
  },
  setup() {
    const audio = computed({
      get: () => selectedAudioInput.value,
      set: (selectedAudioInput) => setAudioInput(selectedAudioInput),
    });

    const canvas = ref(null);
    let micLevel;

    watch(publisher, (publisher, old) => {
      if (!old && publisher?.id) {
        micLevel = new MicTest({ canvas: canvas.value });
        micLevel.init();
      }
    });

    onMounted(() => {
      if (publisher.value) {
        audio.value = selectedAudioInput.value;
        micLevel = new MicTest({ canvas: canvas.value });
        micLevel.init();
      }
    });

    onUnmounted(() => {
      if (micLevel) {
        micLevel?.onDestroy();
      }
    });

    return {
      canvas,
      audioInputs,
      audio,
    };
  },
});
