
import { defineComponent, onMounted, onBeforeUnmount, ref, toRefs, watch } from 'vue';
import SubscriberAdapter from '../../../services/SubscriberAdapter';
import { selectedAudioOutput, streams, publisherElement } from '../../../store/session';

import { clinician } from '../../../store/user';
import UserMenu from './UserMenu.vue';
import { whiteboardEnabled, callLayout } from '../../../store/views';
import ColorPickerModal from './ColorPickerModal.vue';
import UserNameFormModal from './UserNameFormModal.vue';
import OverlayPanel from 'primevue/overlaypanel';

export default defineComponent({
  name: 'Attendee',
  props: {
    attendee: {
      type: Object,
      required: true,
    },
  },
  components: {
    UserMenu,
    OverlayPanel,
    ColorPickerModal,
    UserNameFormModal,
  },
  setup(props) {
    const root = ref(null);
    //ref the Overlay Panel so we can control it
    const op = ref(null);
    const { attendee } = toRefs(props);
    const idString = ref('OT_subscriber');
    const showButtons = ref(false);
    const showUserMenu = ref(false);
    const showColorPicker = ref(false);
    const showUserNameForm = ref(false);
    const focusView = ref(false);
    const subscriberAdapter = new SubscriberAdapter();
    const connectionId = attendee.value.connectionId;
    const order = ref(`order: ${attendee.value.index}`);

    onMounted(() => {
      if (streams.value.hasOwnProperty(attendee.value.streamId as string)) {
        subscriberAdapter.subscribe(
          attendee.value.streamId as string,
          {
            insertMode: 'append',
            height: '100%',
            width: '100%',
            showControls: false,
            insertDefaultUI: false,
          },
          (e) => {
            if (e) console.log(e);
          },
        );
      } else {
        root.value.insertAdjacentElement('afterbegin', publisherElement.value);
      }
    });

    watch(
      () => attendee.value.videoEl,
      (newVal, oldVal) => {
        if (newVal && !oldVal) {
          root.value.appendChild(attendee.value.videoEl);
        }
      },
    );

    watch(selectedAudioOutput, () => {
      subscriberAdapter.attachAudioOutputToVideo();
    });

    /**
     * on full screen change the view
     */
    const onFullscreen = (e): void => {
      if (!focusView.value) {
        document.querySelector('#main_focus_viewer').append(root.value);
        focusView.value = true;
      } else {
        document.querySelector('#subscribers').append(root.value);
        focusView.value = false;
      }
    };

    /**
     * show the user menu
     */
    const setShowUserMenu = (e): void => {
      showButtons.value = false;
      showUserMenu.value = true;
    };

    /**
     * hide the user menu
     */
    const setHideUserMenu = (e): void => {
      showUserMenu.value = false;
    };

    /**
     * toggle the user menu Overlay Panel
     */
    const toggleOverlayPanel = (event): void => {
      op.value.toggle(event);
    };

    /**
     * toggle the color picker modal
     */
    const toggleColorPickerModal = (): void => {
      showColorPicker.value = !showColorPicker.value;
    };

    /**
     * toggle the color picker modal
     */
    const toggleUserNameFormModal = (): void => {
      showUserNameForm.value = !showUserNameForm.value;
    };

    /**
     * assign the name plate color on load and change
     */
    const assignNamePlateColor = () => {
      return !order.value ? '#000' : attendee.value.color;
    };

    const assignTileClass = () => {
      return callLayout.value === 'tiled' ? 'tiled-nameplate' : '';
    };

    return {
      root,
      connectionId,
      idString,
      clinician,
      showButtons,
      showUserMenu,
      setShowUserMenu,
      showColorPicker,
      showUserNameForm,
      toggleColorPickerModal,
      toggleUserNameFormModal,
      setHideUserMenu,
      onFullscreen,
      focusView,
      op,
      toggleOverlayPanel,
      assignNamePlateColor,
      order,
      attendee,
      whiteboardEnabled,
      callLayout,
      assignTileClass,
    };
  },
});
