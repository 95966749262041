
import { ref, defineComponent, computed, toRefs } from 'vue';
import { clearUserHistory } from '../../../store/whiteboard/actions';
import { getAttendeeByConnectionId } from '../../../store/attendees';
import { AttendeeInfo } from '@/utils/Types';
import { whiteboardEnabled } from '../../../store/views';
import MenuItem from "./MenuItem.vue";

//Menu items outside of component to prevent loading every time
const menuItems = [
  { icon: 'mic_off', title: 'Mute', action: "test" },
  { icon: 'videocam_off', title: 'Turn off video', action: "test" },
  { icon: 'message', title: 'Send message', action: "test" },
  { icon: 'description', title: 'Open notes', action: "test" },
  { icon: 'edit', title: 'Edit Name', action: "toggleUserNameFormModal.value" },
  { icon: 'edit', title: 'Edit Color', action: "toggleColorPickerModal.value" },
  { icon: 'edit', title: 'Clear Whiteboard', action: "clearHistory" },
  { icon: 'close', title: 'Remove from meeting', action: "test" },
]

export default defineComponent({
  name: 'UserMenu',
  emits: ['close'],
  components: {
    MenuItem
  },
  props: ['id', 'toggleColorPickerModal', 'toggleUserNameFormModal'],
  setup(props) {
    const { id, toggleColorPickerModal, toggleUserNameFormModal } = toRefs(props);

    const showCloseButton = ref(false);
    const showUserNameForm = ref(false);

    //handle the action as a string
    const handleMenuItemAction = (e) => {

      //put in a try/catch to catch any mismatch refs
      try {
        const action = eval(e);
        action();
      } catch (e) {
        console.log(e)
      }
    }

    const clearHistory = () => {
      if (whiteboardEnabled.value) {
        clearUserHistory(id.value as string);
      }
    }
    const showUserNameFormModal = () => {
      showUserNameForm.value = true;
    };

    const hideUserNameFormModal = () => {
      showUserNameForm.value = false;
    };

    return {
      handleMenuItemAction,
      toggleColorPickerModal,
      toggleUserNameFormModal,
      clearHistory,
      showCloseButton,
      hideUserNameFormModal,
      showUserNameFormModal,
      showUserNameForm,
      whiteboardEnabled,
      menuItems
    };
  },
});
