
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { focusedAttendee } from '../../../store/attendees';

export default defineComponent({
  name: 'FocusedAttendee',
  setup() {
    const root = ref<HTMLCanvasElement>(null);
    const context = ref<CanvasRenderingContext2D>(null);
    const currentSpeakerElement = ref(null);

    let rAFId = null;
    //ref the Overlay Panel so we can control it

    const drawVideo = () => {
      console.log(currentSpeakerElement);
      root.value.width = root.value.scrollWidth;
      root.value.height = root.value.scrollHeight;
      if (currentSpeakerElement.value) {
        const { renderSize, xOffset, yOffset } = getCanvasParameters();
        context.value.clearRect(xOffset, yOffset, renderSize.width, renderSize.height);
        context.value.drawImage(
          currentSpeakerElement.value,
          xOffset,
          yOffset,
          renderSize.width,
          renderSize.height,
        );
        rAFId = requestAnimationFrame(drawVideo);
      }
    };

    const clearVideo = () => {
      if (currentSpeakerElement.value) {
        const { renderSize, xOffset, yOffset } = getCanvasParameters();

        context.value.clearRect(xOffset, yOffset, renderSize.width, renderSize.height);
      }
    };

    const getCanvasParameters = () => {
      const videoSize = {
        width: currentSpeakerElement.value.videoWidth,
        height: currentSpeakerElement.value.videoHeight,
      };
      const canvasSize = { width: root.value.width, height: root.value.height };
      const renderSize = calculateSize(videoSize, canvasSize);
      const xOffset = (canvasSize.width - renderSize.width) / 2;
      const yOffset = (canvasSize.height - renderSize.height) / 2;

      return {
        renderSize,
        xOffset,
        yOffset,
      };
    };

    const calculateSize = (srcSize, dstSize) => {
      const srcRatio = srcSize.width / srcSize.height;
      const dstRatio = dstSize.width / dstSize.height;
      if (dstRatio > srcRatio) {
        return {
          width: dstSize.height * srcRatio,
          height: dstSize.height,
        };
      } else {
        return {
          width: dstSize.width,
          height: dstSize.width / srcRatio,
        };
      }
    };

    watchEffect(() => {
      if (rAFId) cancelAnimationFrame(rAFId);
      if (focusedAttendee.value) {
        currentSpeakerElement.value = focusedAttendee.value.videoEl;
        drawVideo();
      } else {
        clearVideo();
      }
    });

    onMounted(() => {
      context.value = root.value.getContext('2d');
    });

    return {
      root,
    };
  },
});
