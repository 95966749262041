
import { defineComponent } from 'vue';
import { WhiteboardColor } from '../../../utils/Types';

export default defineComponent({
  name: 'ColorButton',
  props: {
    color: {
      type: String,
      required: true,
    },
    borderStyle: {
      type: String,
      required: false,
      default: '2px solid white',
    },
    tempColor: {
      type: String,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
  },
});
