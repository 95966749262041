import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Harlem from '@harlem/core';
import Components from './components';
import PrimeVue from 'primevue/config';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import YoutubeIframe from '@techassi/vue-youtube-iframe';
import createDevtoolsPlugin from '@harlem/plugin-devtools';
import BadgeDirective from 'primevue/badgedirective';

// import createSocketPlugin from './plugins/SocketPlugin';
// import SocketIO from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';
// let plugins = [];

// if (process.env.NODE_ENV === 'development') {
// const optionsVueIO = {
//   debug: true,
//   connection: SocketIO('http://localhost:8000')
// };

const devtoolsPlugin = createDevtoolsPlugin({
  label: 'State',
});
// const socketPlugin = createSocketPlugin({
//   option: 'Option'
// });
// }
const app = createApp(App);

app
  //@ts-ignore
  .use(YoutubeIframe)
  .use(Components)
  .use(router)
  .use(PrimeVue)
  .use(Harlem, {
    plugins: [devtoolsPlugin],
  })
  .directive('badge', BadgeDirective)
  .mount('#app');
