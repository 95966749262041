
import { defineComponent, ref } from 'vue';
import { streams, callActive } from '../../../store/session';
import { pendingAttendees, connectedAttendeeCount } from '../../../store/attendees';
import { clinician } from '../../../store/user';

export default defineComponent({
  name: 'ElapsedTime',
  setup() {
    const timeElapsed = ref('Waiting');
    const timeElapsedExtra = ref('');

    setInterval(() => {
      const streamObject = streams.value;
      const callStarted: boolean = callActive.value;
      // Leaving all of this commented code here in case we decide to have some indicator text
      // for how many people are in lobby and such


      // const activeStreams: boolean = !!Object.keys(streamObject).length;
      // const attendeesAwaitingApproval: number = pendingAttendees.value.length;
      // const isHost: boolean = clinician.value;

      if (!callStarted) {
      //   if (!attendeesAwaitingApproval) {
      //     // if (!isHost) {
      //     //   // setJoinDisabled(true);
      //     // }
          return;
      //   }
      //   // setJoinDisabled(false);
      //   if (isHost) {
      //     timeElapsed.value = `${attendeesAwaitingApproval} waiting for apporoval. `;
      //     timeElapsedExtra.value = `${connectedAttendeeCount.value -
      //       attendeesAwaitingApproval -
      //       1} in lobby`;
      //   }
      //   if (!isHost) {
      //     timeElapsed.value = 'Clinician in Room';
      //   }
      //   return;
      // } else if (callStarted && !activeStreams) {
      //   timeElapsedExtra.value = '';
      //   if (attendeesAwaitingApproval && !isHost) {
      //     timeElapsed.value = 'Waiting for approval. ';
      //     return;
      //   } else if (isHost) {
      //     timeElapsed.value = `${attendeesAwaitingApproval} waiting for approval.  `;
      //     timeElapsedExtra.value = `${connectedAttendeeCount.value -
      //       attendeesAwaitingApproval -
      //       1} in lobby`;
      //     return;
      //   } else {
      //     timeElapsed.value = `Wait for clinician to join`;
      //   }
      }
      const currentStreams = Object.values(streamObject);
      const start =
        (currentStreams as any) &&
        (currentStreams.length as any) &&
        (currentStreams[0] as any).creationTime;
      if (start) {
        let t = Date.parse(new Date().toString()) - Date.parse(new Date(start).toString());
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);
        timeElapsed.value = `${minutes}:${seconds > 9 ? seconds : '0' + seconds}`;
        timeElapsedExtra.value = null;
      }
    }, 1000);

    return { timeElapsed, timeElapsedExtra };
  },
});
