import { NoteGroup, NoteTopics } from '../../utils/Types';

import { mutation } from './store';

export const toggleNoteWindow = mutation('toggleNoteWindow', (state, payload: boolean) => {
  state.open = payload;
});

/**
 * set the note group to selected
 */
export const setNoteGroupSelected = mutation('setNoteGroupSelected', (state, payload: string) => {
  state.noteGroupSelected = payload;
});

/**
 * change the chat display name to first name and first initial of last name
 * @param val
 * @returns String
 */
const truncateName = (val) => {
  const splitName = val.split(' ');
  if (splitName.length > 1) {
    return splitName[0] + ' ' + splitName[1].charAt(0) + '.';
  }
  return val;
};

/**
 * add a new note group for attendee
 */
export const addNoteGroupForAttendee = mutation(
  'addNoteGroupForAttendee',
  (state, { id, name }: { id: string } & Pick<NoteGroup, 'name'>) => {
    const displayName = truncateName(name);
    const emptyNoteDataObject = Object.fromEntries(Object.keys(NoteTopics).map((key) => [key, '']));
    state.noteGroups[id] = {
      name: displayName,
      noteTopics: Object.values(NoteTopics),
      noteData: emptyNoteDataObject,
    };
    if (Object.keys(state.noteGroups).length == 1) {
      setNoteGroupSelected(id);
    }
  },
);

/**
 * add a new note message
 */
export const addNoteText = mutation(
  'addNoteText',
  (state, { id, topic, data }: { id: string; topic: NoteTopics; data: string }) => {
    state.noteGroups[id].noteData[topic] = data;
  },
);
