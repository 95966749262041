
import { defineComponent, toRefs, ref } from 'vue';

export default defineComponent({
  name: 'IconBase',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 40,
    },
    height: {
      type: [Number, String],
      default: 40,
    },
    iconColor: {
      type: String,
      required: false,
      default: '#718484',
    },
    iconHoverColor: {
      type: String,
      required: false,
      default: '#10ACA2',
    },
    viewBox: {
      type: String,
      required: false,
      default: `0,0,40,40`,
    },
  },
  setup(props) {
    const { iconName, width, height, iconColor, iconHoverColor, viewBox } = toRefs(props);

    const isHover = ref(false);

    return { iconName, width, height, iconColor, iconHoverColor, isHover, viewBox };
  },
});
