import STATE from './state';
import actionExtension from '@harlem/extension-action';
import { ModeratorState } from '../../utils/Types';

import { createStore, Extension } from '@harlem/core';

export const { state, getter, mutation, action, ...store } = createStore<
  ModeratorState,
  Extension<ModeratorState>[]
>('moderator', STATE, {
  extensions: [actionExtension()],
});
