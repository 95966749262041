import Builder from './Builder';

import { DisconnectCallOptions } from './Types';

import { userId } from '../store/user';

import { meetingId } from '../store/env';

import { setSessionDisconnected, setHostStatus } from '../store/session';

import { addConnectedAttendee } from '../store/attendees';

export default class CallActions {
  static async joinCall(meetingOptions?: any) {
    let response;
    try {
      response = await Builder.get(`/session/user/join/${meetingId.value}`, 'join');
      this.fetchCallStatus();
    } catch (e) {
      throw e;
    }
    return response.data;
  }

  static async fetchCallStatus() {
    let meetingStatus, connectionsStatus, response;
    try {
      meetingStatus = await Builder.get(`/session/info/status/${meetingId.value}`, 'status');
      connectionsStatus = await Builder.get(
        `/session/info/connections/${meetingId.value}`,
        'connections',
      );
      response = await Builder.all([meetingStatus, connectionsStatus]);
    } catch (e) {
      throw e;
    }

    const status = response[0].data;
    const connections: { connections: any[]; role: string; session_id: number; user_id: number }[] =
      response[1].data;
    setHostStatus(status.hostHasJoined);

    for (let connection of connections) {
      if (connection.connections.length) {
        let mostRecentConnection = connection.connections.length - 1;
        addConnectedAttendee({
          role: connection.role,
          userId: connection.user_id,
          connectedAt: connection.connections[mostRecentConnection].connected_at,
        });
      }
    }
  }

  static async disconnect(user?: number) {
    const options: DisconnectCallOptions = {
      user_id: user || userId.value,
    };
    let response;
    try {
      response = await Builder.post('/session/disconnect', options);
      setSessionDisconnected();
      return response;
    } catch (e) {
      setSessionDisconnected();
      throw e;
    }
  }
}
